import React from "react";
import Cookie from "js-cookie";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//GENERAL
import Login from "./views/login";
import Register from "./views/register";
import Dashboard from "./views/dashboard/Dashboard";
import Profile from "./views/Profile/profile";
import BillingMethods from "./views/Profile/billing-methods.jsx";
import BillingMethodsEmployee from "./views/Profile/billing-methods-employee.jsx";
import EmployerProfile from "./views/Profile/company_information";
import EmployeeProfile from "./views/Employeee/profile";
import VendorProfile from "./views/Vendor/profile";
import ChoosePofile from "./views/Profile/chooseProfile";
import SwitchPofile from "./views/Profile/profileSwitch";
//EMPLOYER
import EmployerOnboarding from "./views/Employer/employee_onboarding/employee_onboarding";
import ViewEmployees from "./views/Employer/employee_onboarding/employee_actions/view_employee";
import CompanyInformation from "./views/Profile/company_information";
import Security from "./views/Profile/security";
import Subscription from "./views/Profile/subscription/subscription";
import Billing from "./views/Profile/accounts";
import TeamManagement from "./views/Employer/teams/teams";
import Payroll from "./views/Employer/payroll/payroll";
import Position from "./views/Employer/position/position";
import Department from "./views/Employer/department/departments";
import IndividualOnboarding from "./views/Employer/Individual-Onboarding/index";
import OnboardingPersonalInfo from "./views/Employer/Individual-Onboarding/personal-info";
import OnboardingPaymentInfo from "./views/Employer/Individual-Onboarding/payment-info";
import OnboardingWorkInfo from "./views/Employer/Individual-Onboarding/work-info";

import Levels from "./views/Employer/levels/levels";
import EmployerOnboardingDetails from "./views/Employer/Individual-Onboarding/OnboardingDetails";
import PayGroup from "./views/Employer/paystructure/paygroup";
import ViewPaystructure from "./views/Employer/paystructure/view_paystructure";
import EmployeeSalaryDetail from "./views/Employer/employee_onboarding/employee_actions/salary_detail";
import EmployeeAllowance from "./views/Employer/employee_onboarding/employee_actions/allowance";
import EmployeeDeduction from "./views/Employer/employee_onboarding/employee_actions/decution";
import EmployeePaymentMethods from "./views/Employer/employee_onboarding/employee_actions/payment-method";
import PayrollDetails from "./views/Employer/payroll/payroll_details";
import TaxDetails from "./views/Employer/Tax/taxDetails";
import EmployerAccAuditTrails from "./views/Employer/audit_trail/audit";
import EmployerBenefits from "./views/Employer/employee_benefits/employee_benefits";
import EmployerTransactions from "./views/Employer/transactions/transactions";
import BenefitsDetail from "./views/Employer/employee_benefits/employee_benefit_details";
import EmployerBenefitss from "./views/Employer/employee_benefits/employee_benefits";
import EmployerPension from "./views/Employer/pension/pension";
import Tax from "./views/Employer/Tax/tax";
import EmployeePersonalInfo from "./views/Employeee/EmployeeAcceptOnbording/personal-info";
import EmployeeAccountInfo from "./views/Employeee/EmployeeAcceptOnbording/account-info";
import EmployeePaymentInfo from "./views/Employeee/EmployeeAcceptOnbording/payment-info";

import EmployeeOffOnboarding from "./views/Employer/employee_onboarding/employee_actions/employee_off_onboarding";
import EmployerPensionDetails from "./views/Employer/pension/pension-detail";
import EmployerLeaveRequest from "./views/Employer/employee_onboarding/employee_actions/leave-request";
//EMPLOYEEE
import EmployeeInvite from "./views/Employeee/employee_invite";
import AirtimeBenefit from "./views/Employeee/benefits/airtime_benefit";
import DataBenefit from "./views/Employeee/benefits/data_benefit";
import EmployeeCards from "./views/Employeee/Cards/Index";
import BuyAirtime from "./views/Employeee/buy_Airtime/buy_airtime";
import EmployeeGetStarted from "./views/Employeee/EmployeeAcceptOnbording/getstarted";
import PaymentMethod from "./views/Employeee/payment_method/payment_method";
import DataBundle from "./views/Employeee/data_bundle/data_bundle";
import Investment from "./views/Employeee/investment/investment";
import MarketPlace from "./views/Employeee/market_place/market_place";
import CreditCard from "./views/Employeee/credit_check/credit_check";
import BillPayments from "./views/Employeee/bill_payments/bill_payments";
import EmployeeAccAuditTrails from "./views/Employeee/audit_trail/audit";
import Accounts from "./views/Profile/employee-accounts";
import Insurance from "./views/Employeee/insurance/insurance";
import MyWorkHub from "./views/Employeee/workhub/my-work-hub";
import EmployeeOffboarding from "./views/Employeee/workhub/employeeOffboarding.jsx";
import EmployerEmployee from "./views/Employeee/employer/employer";
import Cooperative from "./views/Employeee/cooperative/cooperative";
import SalaryAdvance from "./views/Employeee/salary_Advance/salary_advance";
import Pension from "./views/Employeee/pension/pension";
import EmployeePayrollDetails from "./views/Employer/employee_onboarding/employee_actions/payroll_details";
import EmployeePaymentHistory from "./views/Employeee/paymentHistory/paymentHistory";
import EmployeeTransactionHistory from "./views/Employeee/TransactionHistory/transactionHistory";
import EmployeeDeductionHistory from "./views/Employeee/Deductions/deductionHistory";
import EmployeeBenefits from "./views/Employeee/benefits/benefits";
import EmployeeEmployerDeductions from "./views/Employeee/employer/employee_actions/deductions";
import EmployeeEmployerLeaveRequest from "./views/Employeee/leave-request/leave-req";
import EmployeeEmployerAllowances from "./views/Employeee/employer/employee_actions/allowances";
import EmployeeEmployerTaxes from "./views/Employeee/employer/employee_actions/taxes";
import EmployeeEmployerPensions from "./views/Employeee/employer/employee_actions/pensions";
import EmployeeEmployerProfile from "./views/Employeee/employer/employee_actions/employee-profile";
import CardDetails from "./views/Employeee/Cards/details";
import BeneficiaryAccount from "./views/Employeee/Beneficiaries/account.jsx";
import BeneficiaryBills from "./views/Employeee/Beneficiaries/bills";
import PayBills from "./views/Employer/PayBill/Index.jsx";

//SUPERADMINS
import Employers from "./views/SuperAdmin/employers/employers";
import SuperAdmin from "./views/SuperAdmin/superadmin/superadmin";
import Admin from "./views/SuperAdmin/admins/admins";
import Country from "./views/SuperAdmin/country/country";
import Currency from "./views/SuperAdmin/currency/currency";
import PaymentOption from "./views/SuperAdmin/payment_option/payment_option";
import Role from "./views/SuperAdmin/role/role";
import SubscriptionPlan from "./views/SuperAdmin/subcription_plan/subscription_plan";
import Subscriptions from "./views/SuperAdmin/subscription/subscription";
import AllAccounts from "./views/SuperAdmin/accounts/accounts";
import LoanManagement from "./views/SuperAdmin/loans/loans.js";
import ProcessorManagement from "./views/SuperAdmin/processor_management/processor_management";
import SuperAdminAuditTrails from "./views/SuperAdmin/audit_trails/audit_trails";
import Options from "./views/SuperAdmin/options/options";
import Benefits from "./views/SuperAdmin/employer_benefits/employer_benefits";
import EmployerPayroll from "./views/SuperAdmin/employer_payroll/employer_payroll";
import EmployerTransaction from "./views/SuperAdmin/employer_transaction/employer_transaction";
import EmployerAuditTrails from "./views/SuperAdmin/employer_audit_trail/employer_audit_trail";
import EmployerLoan from "./views/SuperAdmin/employer_loan/employer_loan";
import AdminProfile from "./views/SuperAdmin/profile/profile.jsx";
import ViewEmployer from "./views/SuperAdmin/employers/view_employer";
import ViewEmployeesAdmin from "./views/SuperAdmin/employees/employees.jsx";
import NewsLetter from "./views/SuperAdmin/newsletter/newsletter";
import Banks from "./views/SuperAdmin/banks/banks";
import Branches from "./views/SuperAdmin/branches/branches";
import Pfa from "./views/SuperAdmin/pfa/pfa";
import Crypto from "./views/SuperAdmin/crypto_currency/crypto_currencies";
import LoanInterest from "./views/SuperAdmin/loan_interest/loan_interest";
import Processor from "./views/SuperAdmin/processors/processors";
import ProcessorActions from "./views/SuperAdmin/processors/processors-action";
import ViewProcessorAction from "./views/SuperAdmin/processors/view-processor-action";
import KYC from "./views/SuperAdmin/kyc/kyc";
import PersonalInformation from "./views/SuperAdmin/kyc/personal_information";
import BusinessInformation from "./views/SuperAdmin/kyc/business_information";
import Documents from "./views/SuperAdmin/kyc/documents";
import Identities from "./views/SuperAdmin/kyc/identities";
import FeeStructure from "./views/SuperAdmin/fee_structure/fee_structure";
import Privileges from "./views/SuperAdmin/privileges/privileges";
import RolePrivilege from "./views/SuperAdmin/loans/role_privilege.jsx";
import SuperAdminTrans from "./views/SuperAdmin/transactions/transactions.jsx";
import DeleteAccount from "./views/delete-account.jsx"
//VENDOR
import VendorRegister from "./views/Vendor/register";
import VendorInfo from "./views/Employeee/market_place/vendor-view";
import VendorList from "./views/Employeee/market_place/vendor-list";
import VendorPaymentHistory from "./views/Employeee/market_place/vendor_payment_history";

//PROVIDERS
import Test from "./views/tests/test";

var userType = Cookie.getJSON("salarioPayUserInfo");
const role = sessionStorage.getItem("activeRole");

class Show extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/register" exact component={Register} />{" "}
          <Route path="/login" exact component={Login} />{" "}
          <Route path="/" exact component={Login} />{" "}
          <Route path="/dashboard" exact component={Dashboard} />{" "}
          <Route path="/delete-account" exact component={DeleteAccount} />{" "}
          <Route path="/choose-profile" exact component={ChoosePofile} />{" "}
          <Route path="profile-switch" exact component={SwitchPofile} />{" "}
          <Route path="/airtime_benefits" exact component={AirtimeBenefit} />{" "}
          <Route path="/data-bundle_benefits" exact component={DataBenefit} />{" "}
          <Route path="/card-details/:id" exact component={CardDetails} />{" "}
          {
            {
              EMPLOYER: (
                <Route path="/profile" exact component={EmployerProfile} />
              ),
              EMPLOYEE: (
                <Route path="/profile" exact component={EmployeeProfile} />
              ),
              SUPER_ADMIN: (
                <Route path="/profile" exact component={AdminProfile} />
              ),
              ACCOUNTANT: (
                <Route path="/profile" exact component={EmployeeProfile} />
              ),
              HUMAN_RESOURCES: (
                <Route path="/profile" exact component={EmployeeProfile} />
              ),
              SUPERADMIN: <SuperAdmin />,
              VENDOR: <Route path="/profile" exact component={VendorProfile} />,
            }[role]
          }
          <Route
            path="/employee_onboarding"
            exact
            component={EmployerOnboarding}
          />{" "}
          <Route path="/view_employee" exact component={ViewEmployees} />{" "}
          <Route path="/employers" exact component={Employers} />{" "}
          <Route path="/super_admins" exact component={SuperAdmin} />{" "}
          <Route path="/admins" exact component={Admin} />{" "}
          <Route path="/countries" exact component={Country} />{" "}
          <Route path="/currencies" exact component={Currency} />{" "}
          <Route path="/payment_options" exact component={PaymentOption} />{" "}
          <Route path="/roles" exact component={Role} />{" "}
          <Route path="/employer/bill-payment" exact component={PayBills} />{" "}
          <Route
            path="/subscription_plans"
            exact
            component={SubscriptionPlan}
          />{" "}
          <Route path="/subscription" exact component={Subscription} />{" "}
          <Route
            path="/company_information"
            exact
            component={CompanyInformation}
          />{" "}
          <Route path="/security" exact component={Security} />{" "}
          <Route
            path="/accounts"
            exact
            component={role === "EMPLOYEE" ? Accounts : Billing}
          />{" "}
          <Route path="/teams" exact component={TeamManagement} />{" "}
          <Route path="/all_account" exact component={AllAccounts} />{" "}
          <Route path="/loans" exact component={LoanManagement} />{" "}
          <Route path="/processor" exact component={ProcessorManagement} />{" "}
          <Route path="/subscriptions" exact component={Subscriptions} />{" "}
          <Route path="/options" exact component={Options} />{" "}
          <Route path="/benefits" exact component={Benefits} />{" "}
          <Route
            path="/employer_view_payroll"
            exact
            component={EmployerPayroll}
          />{" "}
          <Route
            path="/employer_view_transactions"
            exact
            component={EmployerTransaction}
          />{" "}
          <Route
            path="/employer_view_audit_trail"
            exact
            component={EmployerAuditTrails}
          />{" "}
          <Route path="/employer_view_loan" exact component={EmployerLoan} />{" "}
          <Route path="/manage-position" exact component={Position} />{" "}
          <Route path="/manage-department" exact component={Department} />{" "}
          <Route path="/view_employer" exact component={ViewEmployer} />{" "}
          <Route path="/view_employees" exact component={ViewEmployeesAdmin} />{" "}
          <Route path="/security" exact component={Security} />{" "}
          <Route path="/news_letter" exact component={NewsLetter} />{" "}
          <Route path="/banks" exact component={Banks} />{" "}
          <Route path="/branches" exact component={Branches} />{" "}
          <Route path="/tax" exact component={Tax} />{" "}
          <Route
            path="/pfa
            "
            exact
            component={Pfa}
          />{" "}
          <Route
            path="/billing-methods"
            exact
            component={
              role === "EMPLOYER" ? BillingMethods : BillingMethodsEmployee
            }
          />{" "}
          <Route path="/crypto_currency" exact component={Crypto} />{" "}
          <Route path="/employee_invite" exact component={EmployeeInvite} />{" "}
          <Route path="/buy_airtime" exact component={BuyAirtime} />{" "}
          <Route path="/buy_databundle" exact component={DataBundle} />{" "}
          <Route path="/payment_method" exact component={PaymentMethod} />{" "}
          <Route path="/investments" exact component={Investment} />{" "}
          <Route path="/market_place" exact component={MarketPlace} />{" "}
          <Route path="/credit_card" exact component={CreditCard} />{" "}
          <Route path="/payments" exact component={BillPayments} />{" "}
          <Route path="/insurance" exact component={Insurance} />{" "}
          <Route path="/my-work-hub" exact component={MyWorkHub} />{" "}
          <Route path="/offboarding" exact component={EmployeeOffboarding} />{" "}
          <Route path="/employee_employer" exact component={EmployerEmployee} />{" "}
          <Route path="/cooperative" exact component={Cooperative} />{" "}
          <Route path="/flexipay" exact component={SalaryAdvance} />{" "}
          <Route path="/cards" exact component={EmployeeCards} />{" "}
          <Route
            path="/employer_employee-pensions"
            exact
            component={EmployeeEmployerPensions}
          />{" "}
          <Route
            path="/employee-allowances"
            exact
            component={EmployeeEmployerAllowances}
          />{" "}
          <Route
            path="/employer_employee-taxes"
            exact
            component={EmployeeEmployerTaxes}
          />{" "}
          <Route
            path="/employee-deductions"
            exact
            component={EmployeeEmployerDeductions}
          />{" "}
          <Route
            path="/account-beneficiaries"
            exact
            component={BeneficiaryAccount}
          />{" "}
          <Route
            path="/bills-payment-beneficiaries"
            exact
            component={BeneficiaryBills}
          />{" "}
          <Route
            path="/self-onboarding"
            exact
            component={IndividualOnboarding}
          />{" "}
          <Route
            path="/employee-details"
            exact
            component={EmployeeEmployerProfile}
          />{" "}
          <Route
            path="/onboarding-details/:id"
            exact
            component={EmployerOnboardingDetails}
          />{" "}
          {
            {
              EMPLOYER: (
                <Route
                  path="/leave_request"
                  exact
                  component={EmployerLeaveRequest}
                />
              ),
              EMPLOYEE: (
                <Route
                  path="/leave_request"
                  exact
                  component={EmployeeEmployerLeaveRequest}
                />
              ),
            }[role]
          }{" "}
          {/* <Route path="/pension" exact component={Pension} />, */}
          <Route path="/pension" exact component={EmployerPension} />,
          <Route
            path="/vendor-register"
            exact
            component={VendorRegister}
          />{" "}
          <Route path="/vendor-information" exact component={VendorInfo} />{" "}
          <Route path="/payroll" exact component={Payroll} />{" "}
          <Route path="/vendor-list" exact component={VendorList} />{" "}
          <Route
            path="/vendor_payment_history"
            exact
            component={VendorPaymentHistory}
          />{" "}
          <Route path="/employee-level" exact component={Levels} />{" "}
          <Route path="/paygroup" exact component={PayGroup} />{" "}
          <Route
            path="/employee_salary_detail"
            exact
            component={EmployeeSalaryDetail}
          />{" "}
          <Route
            path="/employee_allowance"
            exact
            component={EmployeeAllowance}
          />{" "}
          <Route
            path="/employee-personal-info"
            exact
            component={OnboardingPersonalInfo}
          />{" "}
          <Route
            path="/employee-work-info"
            exact
            component={OnboardingWorkInfo}
          />{" "}
          <Route
            path="/employee-payment-info"
            exact
            component={OnboardingPaymentInfo}
          />{" "}
          <Route
            path="/employee_deduction"
            exact
            component={EmployeeDeduction}
          />{" "}
          <Route
            path="/employee_payment-method"
            exact
            component={EmployeePaymentMethods}
          />{" "}
          <Route
            path="/onboarding-invite"
            exact
            component={EmployeeGetStarted}
          />{" "}
          <Route
            path="/get-started/personal-info"
            exact
            component={EmployeePersonalInfo}
          />{" "}
          <Route
            path="/get-started/payment-info"
            exact
            component={EmployeePaymentInfo}
          />{" "}
          <Route
            path="/get-started/account-info"
            exact
            component={EmployeeAccountInfo}
          />{" "}
          <Route path="/view_paystructure" exact component={ViewPaystructure} />{" "}
          <Route
            path="/employee_payroll-detail"
            exact
            component={EmployeePayrollDetails}
          />{" "}
          <Route path="/payroll_details" exact component={PayrollDetails} />{" "}
          <Route
            path="/audit-trails"
            exact
            component={
              {
                EMPLOYER: EmployerAccAuditTrails,
                EMPLOYEE: EmployeeAccAuditTrails,
                SUPER_ADMIN: SuperAdminAuditTrails,
              }[role]
            }
          />{" "}
          <Route
            path="/transactions"
            exact
            component={
              {
                EMPLOYER: EmployerTransactions,
                EMPLOYEE: EmployeeTransactionHistory,
                SUPER_ADMIN: SuperAdminTrans,
              }[role]
            }
          />
          <Route path="/subscriptions" exact component={Subscriptions} />{" "}
          <Route
            path="/employer-benefits"
            exact
            component={EmployerBenefitss}
          />{" "}
          <Route path="/benefit_detail" exact component={BenefitsDetail} />{" "}
          <Route path="/loan_product" exact component={LoanInterest} />{" "}
          <Route
            path="/employee_payment_history"
            exact
            component={EmployeePaymentHistory}
          />{" "}
          <Route
            path="/employee_transaction_history"
            exact
            component={EmployeeTransactionHistory}
          />{" "}
          <Route
            path="/employee_deduction_history"
            exact
            component={EmployeeDeductionHistory}
          />{" "}
          <Route path="/employee-benefits" exact component={EmployeeBenefits} />{" "}
          <Route path="/processors" exact component={Processor} />{" "}
          <Route path="/processor-action" exact component={ProcessorActions} />{" "}
          <Route
            path="/view-processor-action"
            exact
            component={ViewProcessorAction}
          />
          <Route path="/kyc" exact component={KYC} />
          <Route
            path="/personal_information/:id"
            exact
            component={PersonalInformation}
          />
          <Route
            path="/business_information/:id"
            exact
            component={BusinessInformation}
          />
          <Route path="/documents/:id" exact component={Documents} />
          <Route path="/identities/:id" exact component={Identities} />
          <Route path="/fee_structure" exact component={FeeStructure} />
          <Route path="/privileges" exact component={Privileges} />
          <Route path="/role_privileges/:id" exact component={RolePrivilege} />
          <Route
            path="/employee-offboarding"
            exact
            component={EmployeeOffOnboarding}
          />
          <Route path="/tax_details" exact component={TaxDetails} />
          <Route
            path="/pension-details"
            exact
            component={EmployerPensionDetails}
          />
        </Switch>{" "}
      </Router>
    );
  }
}
export default Show;
