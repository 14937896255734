export default {
  baseUrl: process.env.REACT_APP_BASE_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  clientUrl: "localhost:3000",
  mono_public_key: "live_pk_RYmEpDBingzuPLnaW4lF",
  stateLists: [
    "ABIA",
    "ADAMAWA",
    "AKWA IBOM",
    "ANAMBRA",
    "BAUCHI",
    "BAYELSA",
    "BENUE",
    "BORNO",
    "CROSS RIVER",
    "DELTA",
    "EBONYI",
    "EDO",
    "EKITI",
    "ENUGU",
    "FCT",
    "GOMBE",
    "IMO",
    "JIGAWA",
    "KADUNA",
    "KANO",
    "KATSINA",
    "KEBBI",
    "KOGI",
    "KWARA",
    "LAGOS",
    "NASARAWA",
    "NIGER",
    "OGUN",
    "ONDO",
    "OSUN",
    "OYO",
    "PLATEAU",
    "RIVERS",
    "SOKOTO",
    "TARABA",
    "YOBE",
    "ZAMFARA",
  ],
  fiscalMonths: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};
