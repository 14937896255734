import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import "./index.css";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Modal,
  DatePicker,
  notification,
  Pagination,
  Select,
  Tabs,
  Checkbox,
} from "antd";
import axios from "axios";
import moment from "moment";
import config from "../../../config";
const dateFormat = "YYYY/MM/DD";
const { Option } = Select;
const { TabPane } = Tabs;
export default function Pension() {
  const [pensionData, setPensionData] = useState([]);
  const [pensionCreate, setPensionCreate] = useState(false);
  const [remitNhf, setRemitNhf] = useState(true);
  const [onboardChecklist, setOnboardChecklist] = useState(false);
  const [remitPension, setRemitPension] = useState(true);
  const [remitTax, setRemitTax] = useState(true);
  const [endPeriod, setEndPeriod] = useState("");
  const [startPeriod, setStartPeriod] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [pensionResponse, setPensionResponse] = useState(false);
  const [pensionSuccessResponseData, setPensionSuccessResponseData] = useState(
    []
  );
  const [pensionErrorResponseData, setPensionErrorResponseData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [PensionAmtModal, setPensionAmtModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [pensionStatus, setPensionStatus] = useState("");
  const [paygroupData, setPaygroupData] = useState([]);
  const [paygroup, setPaygroup] = useState("");
  const [PensionAmt, setPensionAmt] = useState([]);
  const [currencyDatas, setCurrenciesDatas] = useState([]);
  const [failedMsgModal, setFailedMsgModal] = useState(false);
  const [failedMsgs, setFailedMsgs] = useState([]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [countriesData, setCountriesDatas] = useState([]);
  const [activeTab, setActiveTab] = useState(false);
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [userChecklists, setUserChecklist] = useState({});
  const [defaultCurrencies, setDefaultCurrencies] = useState([]);
  const countryLists = [];
  const currencyLists = [];

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [load, setLoad] = useState(false);

  const onCancelOnboardChecklist = () => {
    setOnboardChecklist(false);
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setVisibleDraw(true);
    setStartPeriod(record.period);
    if (record.countries.length > 0) {
      record.countries.map((country) => {
        countryLists.push(country);
        setDefaultCountries(countryLists);
      });
    }
    if (record.currencies.length > 0) {
      record.currencies.map((currency) => {
        currencyLists.push(currency);
        setDefaultCurrencies(currencyLists);
      });
    }
  };


  function callback(key) {
  }

  const showPensionDetails = (record, type, e) => {
    e.preventDefault();
    setType(type);
    setRowID(record.id);
    setPensionAmt(record.pensionAmounts);
    setPensionAmtModal(true);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const getEmployeeInfo = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactInformation = res.data.result;
          setCountriesDatas(contactInformation.countries);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const getCurrencies = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/wallets/currencies/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const result = res.data.result;
          setCurrenciesDatas(result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const getChecklists = () => {
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserChecklist(res.data.result);
          if (
            !res.data.result.billEnabled ||
            !res.data.result.employeeOnboard
          ) {
            setOnboardChecklist(true);
            return;
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closeAdd = () => {
    setPensionCreate(false);
    setCountries([]);
    setCurrencies([]);
    setStartPeriod("");
    setEndPeriod("");
    // setRemitNhf("");
    // setEndPeriod("");
    // setRemitTax("");
    // setRemitTax("");
  };

  const deletePension = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/pensions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          getPensions();
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this Pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePension();
      },
      onCancel() {
      },
    });
  };

  const closeFailedMsg = () => {
    setFailedMsgModal(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pensions/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPensionData(
            pensionData.map((data) => ({
              id: data.id,
              amount: data.amount,
              fee: data.fee,
              countries: data.countries,
              narration: data.narration,
              pensionAmounts: data.pensionAmounts,
              currencies: data.currencies,
              PensionDetails: data.PensionAmount,
              size: data.size,
              period: data.period ? data.period.slice(0, 10) : "",
              status: data.status,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaygroup = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const runPension = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to run this Pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/pensions/run/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              getPensions();
              setTimeout(() => {
                setSuccessStatus(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              getPensions();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
              // setStatusMsg('Failed to connect to SalarioPay')
            }
          });
      },

      onCancel() {
      },
    });
  };

  const stoPension = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to stop this Pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/pensions/stop/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              getPensions();
              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            } else {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              getPensions();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            }
          });
      },
      onCancel() {
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Admin?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
      },
    });
  };

  const currencySelect = [];
  currencyDatas.map((currency) => {
    currencySelect.push(
      <Option key={currency.currency}>{currency.currency}</Option>
    );
  });

  const countrySelect = [];
  countriesData.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });

  const updatePensionswitch = () => {
    setUpdateSwitch(true);
    setPensionCreate(true);
    setVisibleDraw(false);
  };

  const handleChangeCurrency = (value) => {
    setCurrencies(value);
  };

  const handleChangeCountry = (value) => {
    setCountries(value);
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/pensions/status/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPensions();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getPensions();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createPension = () => {
    if (userChecklists.billEnabled && userChecklists.employeeOnboard) {
      setPensionCreate(true);
      setUpdateSwitch(false);
      setOnboardChecklist(false);
    } else {
      setOnboardChecklist(true);
    }
  };

  const columns = [
    {
      key: "amount",
      text: "Amount",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showPensionDetails.bind(this, record, "amount")}
            >
              View
            </Button>
          </Fragment>
        );
      },
    },
    {
      key: "fee",
      text: "Fee",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showPensionDetails.bind(this, record, "fee")}
            >
              View
            </Button>
          </Fragment>
        );
      },
    },

    {
      key: "narration",
      text: "Narration",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "size",
      text: "Size",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "period",
      text: " Period",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "status",
      text: "Status",
      className: "status-color",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
    },
  ];
  const getPensions = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pensions/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const pensionData = res.data.result;
          setPensionData(
            pensionData.map((data) => ({
              id: data.id,
              amount: data.amount,
              fee: data.fee,
              countries: data.countries,
              narration: data.narration,
              pensionAmounts: data.pensionAmounts,
              currencies: data.currencies,
              PensionDetails: data.PensionAmount,
              size: data.size,
              period: data.period ? data.period.slice(0, 10) : "",
              status: data.status,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closePensionResponse = () => setPensionResponse(false);

  const addPension = (e) => {
    e.preventDefault();
    const data = {
      countries: countries,
      currencies: currencies,
      employerId: sessionStorage.getItem("employer_id"),
      period: startPeriod,
    };
    if (data.countries && data.currencies && data.period) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pensions`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setRemitNhf("");
            setPensionCreate(false);
            setCountries([]);
            setCurrencies([]);
            setStartPeriod("");
            setEndPeriod("");
            setEndPeriod("");
            if (res.data.result.pensionDetails) {
              setPensionResponse(true);
              setPensionSuccessResponseData(res.data.result.pensionDetails);
            }
            setRemitTax("");
            setRemitTax("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getPensions();
              setPensionCreate(false);
            }, 3000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setActiveTab(2);
            if (res.data.result.skipped) {
              setPensionResponse(true);
              setPensionErrorResponseData(res.data.result.skipped);
            }
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const viewErrorMsg = (errMsg, e) => {
    e.preventDefault();
    console.log(errMsg);
    setFailedMsgModal(true);
    setFailedMsgs(errMsg);
  };

  const updatePension = (e) => {
    e.preventDefault();
    const data = {
      countries: countries.length > 0 ? countries : defaultCountries,
      currencies: currencies.length > 0 ? currencies : defaultCurrencies,
      employerId: sessionStorage.getItem("employer_id"),
      period: startPeriod,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/pensions/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getPensions();
            setPensionCreate(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getPensions();
    getCurrencies();
    getPaygroup();
    getChecklists();
    getEmployeeInfo();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
    },
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closePensionAmtModal = () => {
    setPensionAmtModal(false);
    setType(false);
  };

  const paygroupOptions = paygroupData.map((paygroup) => {
    return <option value={paygroup.id}>{paygroup.groupName}</option>;
  });

  function onChangeRemitPension(e) {
    setRemitPension(e.target.checked);
  }
  function onChangeRemitTax(e) {
    setRemitTax(e.target.checked);
  }
  function onChangeRemitNhf(e) {
    setRemitNhf(e.target.checked);
  }
  function onChangeStartDate(date, dateString) {
    setStartPeriod(date);
  }
  function onChangeEndDate(date, dateString) {
    setEndPeriod(date);
  }
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="card-title">Pension</h1>
                <button
                  className="btn btn-info "
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createPension}
                >
                  Create Pension
                </button>{" "}
              </div>

              <div className="pt-5">
                {/* <div className='text-center'>
                  {deleteSuccess && (
                    <div className='pb-4'>
                      <Alert message='Role Deleted' type='error' showIcon />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className='pb-4'>
                      <Alert message={statusMsg} type='error' showIcon />
                    </div>
                  )}
                </div> */}
                <ReactDatatable
                  config={configTable}
                  records={pensionData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>

              <div className="text-center pagination-part pt-5">
                <Pagination
                  current={page}
                  total={totalItems}
                  defaultPageSize={10}
                  onChange={pagination}
                  itemRender={itemRender}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link to={{ pathname: "/pension-details", state: { rowId } }}>
          <div style={{ color: "#000000", cursor: "pointer" }}>
            View Pension Details
          </div>
        </Link>
        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={runPension}
        >
          Run Pension
        </div>
        <hr />
        <div style={{ color: "red", cursor: "pointer" }} onClick={stoPension}>
          Stop Pension
        </div>
        <hr />
        <div
          style={{ color: "#1FC157", cursor: "pointer" }}
          onClick={updatePensionswitch}
        >
          Update Pension
        </div>
        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete Pension
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Pension" : "Update Pension"}
        visible={pensionCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}

          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <DatePicker
                  onChange={onChangeStartDate}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Period"
                />
              </div>

              {/* <div className="mb-3">
                <div className="form-floating">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPaygroup(e.target.value)}
                    value={paygroup}
                  >
                    <option selected>Select</option>
                    {paygroupOptions}
                  </select>
                  <label for="floatingInput">Payment Group</label>
                </div>
              </div> */}
              <div class="mb-3">
                <label class="form-label">Countries</label>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  onChange={handleChangeCountry}
                >
                  {countrySelect}
                </Select>
              </div>
              <div class="mb-4">
                <label class="form-label">Currencies</label>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  onChange={handleChangeCurrency}
                >
                  {currencySelect}
                </Select>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div className="mb-3">
                  <DatePicker
                    onChange={onChangeStartDate}
                    defaultValue={moment(startPeriod, dateFormat)}
                    format={dateFormat}
                    style={{
                      width: "100%",
                      height: "3.3rem",
                      borderRadius: "10px",
                    }}
                    placeholder="Start Date"
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label">Countries</label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onChange={handleChangeCountry}
                    defaultValue={defaultCountries}
                  >
                    {countrySelect}
                  </Select>
                </div>

                <div class="mb-4">
                  <label class="form-label">Currencies</label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onChange={handleChangeCurrency}
                    defaultValue={defaultCurrencies}
                  >
                    {currencySelect}
                  </Select>
                </div>
              </div>
            </>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addPension : updatePension}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={type === "amount" ? "Pension Amounts" : "Pension Fees "}
        visible={PensionAmtModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closePensionAmtModal}
      >
        {type === "amount" ? (
          <div>
            {PensionAmt.map((PensionAmt) => {
              return (
                <ul>
                  <li>
                    {PensionAmt.currency} {PensionAmt.amount}
                  </li>
                </ul>
              );
            })}
          </div>
        ) : (
          <div>
            {PensionAmt.map((PensionFee) => {
              return (
                <ul>
                  <li>
                    {PensionFee.currency} {PensionFee.fee}
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </Modal>

      <Modal
        title={false}
        visible={pensionResponse}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={"65%"}
        onCancel={closePensionResponse}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
                <Tabs defaultActiveKey={activeTab} onChange={callback}>
                  <TabPane tab="Success" key="1" style={{ color: "green" }}>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Code</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">PFA Name</th>
                            <th scope="col">PFA RSA Pin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pensionSuccessResponseData.map((data) => {
                            return (
                              <tr>
                                <td>{data.employeeCode}</td>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>
                                  {data.currency} {data.totalAmount}
                                </td>
                                <td>{data.pfaName}</td>

                                <td>{data.rsaPin} </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>

                  <TabPane tab="Failed" key="2">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Code</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">PFA Name</th>
                            <th scope="col">PFA RSA Pin</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pensionErrorResponseData.map((data) => {
                            return (
                              <tr>
                                <td>{data.employeeCode}</td>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>{data.pfaName}</td>
                                <td>{data.rsaPin}</td>
                                <td>
                                  <button
                                    onClick={viewErrorMsg.bind(
                                      this,
                                      data.messages
                                    )}
                                    style={{
                                      background: "red",
                                      color: "white",
                                      border: "1px solid red",
                                    }}
                                    size="small"
                                  >
                                    View Error
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Error Messages"
        visible={failedMsgModal}
        okText="Skip"
        onCancel={closeFailedMsg}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          {failedMsgs.map((msg) => {
            return (
              <div className="pb-2">
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        title=""
        visible={onboardChecklist}
        okText="Skip"
        maskClosable={false}
        width={600}
        onCancel={onCancelOnboardChecklist}
        onOk={onCancelOnboardChecklist}
      >
        <div className="container">
          <div className="check-area pt-2">
            <div className="container">
              <h4 className="text-center" style={{ color: "#1FC157" }}>
                Welcome to SalarioPay!
              </h4>
              <p style={{ color: "#1FC157", whiteSpace: "nowrap" }}>
                Are you ready to experience the next generation payroll for your
                bussiness
              </p>
              <hr />
              <div>
                <h5 className="text-center pt-3">Onboarding Checklist</h5>
                <p className="text-center">
                  Kindly complete the checklist below to get started
                </p>
              </div>

              <div className="check-area pt-2">
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.billEnabled
                        ? userChecklists.billEnabled
                        : ""
                    }
                  >
                    Fund account to run payroll, benefits, pension, and taxes{" "}
                  </Checkbox>
                  <Link to={{ pathname: "/accounts" }}>
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Fund
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.employeeOnboard
                        ? userChecklists.employeeOnboard
                        : ""
                    }
                  >
                    Onboard employee in order to generate payroll{" "}
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/employee_onboarding",
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Onboard
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
