import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import dayjs from "dayjs";
import { ThreeDots } from "react-loader-spinner";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Modal,
  Select,
  DatePicker,
  TimePicker,
  Pagination,
  Tabs,
  notification,
  Checkbox,
} from "antd";
import axios from "axios";
import moment from "moment";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
const dateFormat = "YYYY/MM/DD";
const { TabPane } = Tabs;
const { Option } = Select;

export default function Employers() {
  const [payrollData, setPayrollData] = useState([]);
  const [payrollCreate, setPayrollCreate] = useState(false);
  const [reoccuringCheck, setReoccuring] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [loadPayroll, setLoadPayroll] = useState(false);
  const [occuringTimeMin, setOccuuringTimeMin] = useState("");
  const [changedDateStart, setChangedDateStart] = useState(false);
  const [changedDateEnd, setChangedDateEnd] = useState(false);
  const [loadSearch, setLoadSearch] = useState(false);
  const [fullStartPeriod, setFullStartPeriod] = useState("");
  const [fullEndPeriod, setFullEndPeriod] = useState("");
  const [occuringTimeHour, setOccuuringTimeHour] = useState("");
  const [dayMonth, setDayMonth] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [triggerYear, setTriggerYear] = useState("");
  const [triggerMonth, setMonthVar] = useState("");
  const [loaUpdateSchedule, setLoadUpdateSchedule] = useState(false);
  const [listChecks, setListCheck] = useState([]);
  const [isScheduledPayroll, setIsScheduled] = useState(false);
  const [weekDay, setWeekDay] = useState("");
  const [occuringMonth, setMonthOccuring] = useState("");
  const [remitNhf, setRemitNhf] = useState(true);
  const [frequency, setFrequency] = useState("");
  const [positionOptions, setPositionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [triggerDayOnce, setTriggerDayOnce] = useState("");
  const [levelOptions, setLevelOptions] = useState([]);
  const [depts, setDepts] = useState([]);
  const [positions, setPos] = useState([]);
  const [levels, setevels] = useState([]);

  const [payrollEligibleData, setPayrollSummaryDetailsEligible] = useState([]);
  const [
    filteredpayrollEligibleData,
    setPayrollSummaryDetailsEligibleFiltered,
  ] = useState([]);
  const [payrollUnEligibleData, setPayrollSummaryDetailsUnEligible] = useState(
    []
  );
  const [remitPension, setRemitPension] = useState(false);
  const [payrollDetailsShow, setPayrollDetailsShow] = useState(false);
  const [onboardChecklist, setOnboardChecklist] = useState(false);
  const [remitTax, setRemitTax] = useState(false);
  const [endPeriod, setEndPeriod] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [userChecklists, setUserChecklist] = useState({});
  const [updateScheduleModal, setUpdateScheduleModal] = useState(false);
  const [payrollResponse, setPayrollResponse] = useState(false);
  const [startPeriod, setStartPeriod] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [payrollSummaryData, setPayrollSummaryDetails] = useState([]);
  const [type, setType] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [payrollAmtModal, setpayrollAmtModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [payrollStatus, setPayrollStatus] = useState("");
  const [paygroupData, setPaygroupData] = useState([]);
  const [paygroup, setPaygroup] = useState("");
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [payrollAmt, setPayrollAmt] = useState([]);
  const [currencyDatas, setCurrencyDatas] = useState([]);
  const [countriesData, setCountryDatas] = useState([]);
  const [failedErrMsgs, setFailedErrMessages] = useState([]);
  const [pensionResponse, setPensionResponse] = useState(false);
  const [failedMsgModal, setFailedMsgModal] = useState(false);
  const [payrollSuccessResponseData, setPayrollSuccessResponseData] = useState(
    []
  );
  const [payrollErrorResponseData, setPayrollErrorResponseData] = useState([]);
  const [failedMsgs, setFailedMsgs] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabPayroll, setActiveTabPayroll] = useState("1");
  const [updateData, setUpdateData] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successDecline, setSuccessDecline] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [indStatus, setIndStatus] = useState("");
  const [payrollDatar, setPayrollDatar] = useState({});
  const [openRunPayrollScheduled, setOpenRunPayrollScheduled] = useState(false);
  const [scheduledTime, setScheduleTime] = useState("00:00");

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [load, setLoad] = useState(false);

  const convertISO = (originalDateString) => {
    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);
    // Set the time components to the desired values
    originalDate.setHours(16);
    originalDate.setMinutes(33);
    originalDate.setSeconds(49);
    originalDate.setMilliseconds(752);
    // Convert the Date object to ISO 8601 format with timezone offset
    const isoDateString = originalDate.toISOString();
    return isoDateString;
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    console.log(record);
    setPayrollDatar(record);
    setRowID(record.id);
    setDayMonth(record?.triggerDay);
    if (record?.isScheduled) {
      setScheduleTime(record?.triggerHour + ":" + record?.triggerMinute);
      setOccuuringTimeMin(record?.triggerMinute);
      setOccuuringTimeHour(record?.triggerHour);
      setFrequency(record?.frequency);
      setReoccuring(record?.isScheduled);
      setTriggerYear(record?.triggerYear);
      setMonthVar(record?.triggerMonth);
      setDayMonth(record?.triggerDay);
      setTriggerDayOnce(
        record?.triggerYear +
          "-" +
          "0" +
          record?.triggerMonth +
          "-" +
          record?.triggerDay
      );
    } else {
      setScheduleTime("00:00");
      setOccuuringTimeMin("");
      setOccuuringTimeHour("");
      setFrequency("");
      setReoccuring(false);
      setTriggerYear("");
      setMonthVar("");
      setDayMonth("");
    }

    setType(record.type);
    setCurrencies(record.currency);
    setPayrollStatus(record.status);
    setVisibleDraw(true);
    setEndPeriod(record.endPeriod);
    setStartPeriod(record.startPeriod);
    setFullStartPeriod(record.fullStartPeriod);
    setFullEndPeriod(record?.fullEndPeriod);
    setPaygroup(record.payGroup);
    setIndStatus(record.status);
    closeAdd();
    setUpdateData(record);
    setIsScheduled(record.isScheduled);
  };

  const [loadingType, payrollTypes] = useHttp(
    `${config.baseUrl}/v1/options/PAYROLL_TYPE`,
    []
  );

  const [loadingFreq, frequencyTypes] = useHttp(
    `${config.baseUrl}/v1/options/SCHEDULE_FREQUENCY`,
    []
  );

  const getChecklists = () => {
    setLoadPayroll(true);
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadPayroll(false);
        if (res.data.status) {
          setUserChecklist(res.data.result);
          if (
            !res.data.result.billEnabled ||
            !res.data.result.employeeOnboard
          ) {
            setOnboardChecklist(true);
            return;
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadPayroll(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (!reoccuringCheck) {
      setOccuuringTimeMin("");
      setOccuuringTimeHour("");
      setScheduleTime("00:00");
      setFrequency("");
      setDayMonth("");
    }
  }, [reoccuringCheck]);

  const getEmployeeInfo = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactInformation = res.data.result;
          setCountryDatas(contactInformation.countries);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const getCurrencies = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/wallets/employer/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const result = res.data.result;
          setCurrencyDatas(result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const onChangeTab = (key) => {
    setActiveTabPayroll(key);
  };

  const itemsTab = [
    {
      key: "1",
      label: "Eligible Employees",
      children: "",
    },
    {
      key: "2",
      label: "Uneligible Employees",
      children: "",
    },
  ];

  function callback(key) {}

  const closePayrollResponse = () => setPayrollResponse(false);

  const showPayrollDetails = (record, type, e) => {
    e.preventDefault();
    setType(type);
    setRowID(record.id);
    setPayrollAmt(record.payrollDetails);
    setpayrollAmtModal(true);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const closeAdd = () => {
    setPayrollCreate(false);
    setSelectedEmployees([]);
    setCurrencies([]);
    setCountries([]);
    setPaygroup(null);
    setEndPeriod("");
    setChangedDateEnd(false);
    setChangedDateStart(false);
    setStartPeriod("");
    setType(null);
    setType("");
    // setRemitNhf("");
    // setEndPeriod("");
    // setRemitTax("");
    // setRemitTax("");
  };

  const deletePayroll = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/payrolls/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          getPayrolls();
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const alterBenefitPauseResume = (type) => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/payrolls/${updateData?.id}/schedule`,
        {
          status: type,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccessMsg(res.data.message);
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          getPayrolls();
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const resumePausePayroll = (type) => {
    Modal.confirm({
      title: `Are you sure want to ${
        type === "TERMINATE"
          ? "Terminate"
          : type !== "RESUME"
          ? "Pause"
          : "Resume"
      } this Schedule?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        alterBenefitPauseResume(type);
      },
      onCancel() {},
    });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this Payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePayroll();
      },
      onCancel() {},
    });
  };

  const closeFailedMsg = () => setFailedMsgModal(false);

  const getPaygroup = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const runPayroll = (sch) => {
    var scheduledrunNowText =
      "Are you sure want to run this payroll now and at scheduled?";
    var runAtScheduledText =
      "Are you sure want to run this payroll at scheduled only?";
    var UnscheduledRunText = "Are you sure you want to run this payroll?";
    Modal.confirm({
      title: isScheduledPayroll
        ? sch
          ? runAtScheduledText
          : scheduledrunNowText
        : UnscheduledRunText,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        var urlNow = `${config.baseUrl}/v1/payrolls/run/${rowId}`;
        var urlSchedule = `${
          config.baseUrl
        }/v1/payrolls/run/${rowId}?runNow=${false}`;

        axios
          .put(
            sch ? urlSchedule : urlNow,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setIsScheduled(false);
              setOpenRunPayrollScheduled(false);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              getPayrolls();
              setTimeout(() => {
                setSuccessStatus(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              getPayrolls();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
              // setStatusMsg('Failed to connect to SalarioPay')
            }
          });
      },

      onCancel() {},
    });
  };

  const stopayroll = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to stop this payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(
            `${config.baseUrl}/v1/payrolls/stop/${rowId}`,

            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              getPayrolls();
              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            } else {
              Notification("success", "Success", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              getPayrolls();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            }
          });
      },
      onCancel() {},
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Admin?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {},
    });
  };

  const updatePayrollSwitch = () => {
    setUpdateSwitch(true);
    setPayrollCreate(true);
    setVisibleDraw(false);
    setRowID(updateData.id);
    setType(updateData.type);
    setCurrencies(updateData.currency);
    setPayrollStatus(updateData.status);
    setEndPeriod(updateData.endPeriod?.slice(0, 10));
    setStartPeriod(updateData.startPeriod?.slice(0, 10));
    setPaygroup(updateData.payGroup);
    setIndStatus(updateData.status);
  };

  const onDeleteMultipleEmployees = () => {
    Modal.confirm({
      title: `Are you sure delete these Payrolls(s)`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/payrolls`,
            {
              payrollIds: listChecks?.map((item) => {
                return item.id;
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              getPayrolls();
              setListCheck([]);
              setSelectAll(false);
              Notification("success", "Success", res.data.message);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/roles/status/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPayrolls();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getPayrolls();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createPayroll = () => {
    setOccuuringTimeHour("");
    setReoccuring(false);
    closeAdd();
    setOccuuringTimeMin("");
    setDayMonth("");
    setFrequency("");
    if (userChecklists.employeeOnboard && userChecklists.billEnabled) {
      setPayrollCreate(true);
      setUpdateSwitch(false);
      setOnboardChecklist(false);
    } else {
      setOnboardChecklist(true);
    }
  };

  const columns = [
    {
      key: "status",
      text: "",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <div class="form-check">
              <input
                class="form-check-input border-checks"
                type="checkbox"
                checked={record.checked}
                onChange={() => handleCheckboxChange(record.id)}
              />
            </div>
          </Fragment>
        );
      },
    },
    {
      key: "totalAmount",
      text: "Total Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "totalPayableAmount",
      text: "Payable Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "totalDeduction",
      text: "Total Deduction",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "totalFee",
      text: "Total Fee",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "size",
      text: "Employees",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "startEndPeriodBust",
      text: "Start/End Period",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <div className="d-flex align-items-center">
              <span
                className={
                  {
                    SUCCESS: "badge bg-success",
                    STARTED: "badge bg-warning",
                    COMPLETED: "badge bg-success",
                    SUCCESS: "badge bg-success",
                    FAILED: "badge bg-danger",
                    IDLE: "badge bg-info",
                    PENDING: "badge bg-warning",
                    PROCESSING: "badge bg-warning",
                    TIMEOUT: "badge bg-danger",
                  }[record.status]
                }
              >
                {record.status}
              </span>
              {record?.isScheduled && (
                <i class="fa fa-recycle pl-2" style={{ color: "#000000" }}></i>
              )}
            </div>
          </Fragment>
        );
      },
    },
    {
      key: "created_date",
      text: "Date Created",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    filterRecords(value);
  };

  const filterRecords = (input) => {
    const lowercasedInput = input.toLowerCase();
    const filtered = payrollEligibleData.filter((record) =>
      Object.values(record).some((value) =>
        value.toString().toLowerCase().includes(lowercasedInput)
      )
    );
    setPayrollSummaryDetailsEligibleFiltered(filtered);
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {},
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {},
      onDoubleClick: (event) => {},
    },
  ];
  const getPayrolls = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/payrolls/employer/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const payrollData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPayrollData(
            payrollData.map((data) => ({
              id: data.id,
              totalAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalAmount),
              narration: data.narration,
              totalPayableAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalPayableAmount),
              totalDeduction:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalDeduction),
              totalFee:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalFee),
              payGroup: data.payGroup ? data.payGroup : "NA",
              payrollDetails: data.payrollAmount,
              size: data.totalEmployees,
              checked: false,
              created_date: data?.createdAt
                ? data?.createdAt?.slice(0, 10)
                : "",
              type: data.type,
              isScheduled: data?.isScheduled,
              currency: data.walletId,
              startPeriod: data.startPeriod
                ? data.startPeriod.slice(0, 10)
                : "",

              endPeriod: data.endPeriod ? data.endPeriod.slice(0, 10) : "",
              startEndPeriodBust:
                data?.startPeriod?.slice(0, 10) +
                "/" +
                data?.endPeriod?.slice(0, 10),
              fullEndPeriod: data.endPeriod ? data.endPeriod : "",
              fullStartPeriod: data.startPeriod ? data.startPeriod : "",
              status: data.status,
              frequency: data?.frequency,
              triggerDay: data?.triggerDay,
              triggerHour: data?.triggerHour,
              scheduleStatus: data?.scheduleStatus,
              triggerMinute: data?.triggerMinute,
              triggerMonth: data?.triggerMonth,
              triggerYear: data?.triggerYear,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onCancelOnboardChecklist = () => {
    setOnboardChecklist(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const closeListup = () => {
    setPayrollDetailsShow(false);
  };

  const getPayrollSummarySearch = () => {
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      walletId: currencies,
      endPeriod: updateSwitch ? convertISO(endPeriod?.slice(0, 10)) : endPeriod,
      startPeriod: updateSwitch
        ? convertISO(startPeriod?.slice(0, 10))
        : startPeriod,
      type,
      employeeIds:
        selectedEmployees?.length > 0
          ? selectedEmployees?.map((d) => d?.employeeId)
          : [],
      levels: levels,
      positions: positions,
      departments: depts,
    };
    if (data.startPeriod && data.endPeriod && data.walletId && data.type) {
      setLoadSearch(true);
      axios
        .post(`${config.baseUrl}/v1/payrolls/summary`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadSearch(false);

          if (res.data.status) {
            setPayrollSummaryDetails(res.data.result);
            setPayrollDetailsShow(true);
            setPayrollCreate(false);
            res.data.result?.eligibleEmployees?.forEach((data, index) => {
              data.deduction = data?.deduction
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.deduction)
                : 0;
              data.netSalary = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.netSalary)
                : 0;
              data.payableAmount = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.payableAmount)
                : 0;
              data.fee = data?.fee
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.fee)
                : 0;
            });
            setPayrollSummaryDetailsEligible(
              res.data.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsEligibleFiltered(
              res.data.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsUnEligible(
              res.data.result?.uneligibleEmployees
            );
          } else {
            setPayrollSummaryDetails(res?.data?.result);
            setPayrollDetailsShow(true);
            setPayrollCreate(false);
            res?.data?.result?.eligibleEmployees?.forEach((data, index) => {
              data.deduction = data?.deduction
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.deduction)
                : 0;
              data.netSalary = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.netSalary)
                : 0;
              data.payableAmount = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.payableAmount)
                : 0;
              data.fee = data?.fee
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.fee)
                : 0;
            });
            setPayrollSummaryDetailsEligible(
              res?.data?.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsEligibleFiltered(
              res?.data?.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsUnEligible(
              res?.data?.result?.uneligibleEmployees
            );
          }
        })
        .catch((err) => {
          if (err) {
            setLoadSearch(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const updatePayrollSchedule = () => {
    const data = {
      frequency: frequency,
      status: "UPDATE",
      triggerDay: dayMonth,
      triggerHour: occuringTimeHour,
      triggerMinute: occuringTimeMin,
      triggerMonth: triggerMonth,
      triggerYear: triggerYear,
    };
    setLoadUpdateSchedule(true);
    axios
      .put(`${config.baseUrl}/v1/payrolls/${rowId}/schedule`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadUpdateSchedule(false);
        if (res.data.status) {
          setUpdateScheduleModal(false);
          getPayrolls();
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateSchedule(false);
        if (err) {
        }
      });
  };

  const getPayrollSummary = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      walletId: currencies,
      endPeriod: updateSwitch ? convertISO(endPeriod?.slice(0, 10)) : endPeriod,
      startPeriod: updateSwitch
        ? convertISO(startPeriod?.slice(0, 10))
        : startPeriod,
      type,
      employeeIds: [],
      levels: levels,
      positions: positions,
      departments: depts,
    };
    if (data.startPeriod && data.endPeriod && data.walletId && data.type) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/payrolls/summary`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);

          if (res.data.status) {
            setPayrollSummaryDetails(res.data.result);
            setPayrollDetailsShow(true);
            setPayrollCreate(false);
            res.data.result?.eligibleEmployees?.forEach((data, index) => {
              data.deduction = data?.deduction
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.deduction)
                : 0;
              data.netSalary = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.netSalary)
                : 0;
              data.payableAmount = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.payableAmount)
                : 0;
              data.fee = data?.fee
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.fee)
                : 0;
            });
            setPayrollSummaryDetailsEligible(
              res.data.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsEligibleFiltered(
              res.data.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsUnEligible(
              res.data.result?.uneligibleEmployees
            );
          } else {
            setPayrollSummaryDetails(res?.data?.result);
            setPayrollDetailsShow(true);
            setPayrollCreate(false);
            res?.data?.result?.eligibleEmployees?.forEach((data, index) => {
              data.deduction = data?.deduction
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.deduction)
                : 0;
              data.netSalary = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.netSalary)
                : 0;
              data.payableAmount = data?.netSalary
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.payableAmount)
                : 0;
              data.fee = data?.fee
                ? data?.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data?.fee)
                : 0;
            });
            setPayrollSummaryDetailsEligible(
              res?.data?.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsEligibleFiltered(
              res?.data?.result?.eligibleEmployees
            );
            setPayrollSummaryDetailsUnEligible(
              res?.data?.result?.uneligibleEmployees
            );
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  function extractDateParts(dateString) {
    const [year, month, day] = dateString.split("-");
    const yearVar = parseInt(year, 10);
    const monthVar = parseInt(month, 10);
    const dayVar = parseInt(day, 10);
    setTriggerYear(yearVar);
    setMonthVar(monthVar);
    setDayMonth(dayVar);
  }

  const onChnageDateOnce = (date, datestring) => {
    extractDateParts(datestring);
    setTriggerDayOnce(date);
  };

  const addPayroll = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      walletId: currencies,
      countries: countries,
      employeeIds: selectedEmployees?.map((d) => d?.employeeId),
      endPeriod: updateSwitch ? convertISO(endPeriod?.slice(0, 10)) : endPeriod,
      startPeriod: updateSwitch
        ? convertISO(startPeriod?.slice(0, 10))
        : startPeriod,
      levels: levels,
      positions: positions,
      frequency: frequency,
      isScheduled: reoccuringCheck,
      triggerDay: dayMonth.toString(),
      triggerMonth: triggerMonth.toString(),
      triggerYear: triggerYear.toString(),
      triggerHour: occuringTimeHour.toString(),
      triggerMinute: occuringTimeMin.toString(),
      type,
    };
    if (data.startPeriod && data.endPeriod && data.walletId && data.type) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/payrolls`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);

          if (res.data.status) {
            setRemitNhf("");
            setEndPeriod("");
            closeAdd();
            setPayrollResponse(false);
            setPayrollDetailsShow(false);
            setOccuuringTimeHour("");
            setReoccuring(false);
            setTriggerYear("");
            setMonthVar("");
            setTriggerDayOnce("");
            setOccuuringTimeMin("");
            setDayMonth("");
            setFrequency("");
            setSearchVal("");
            setDepts([]);
            setPos([]);
            setevels([]);
            setRemitTax("");
            setSelectedEmployees(null);
            setRemitTax("");
            setPayrollCreate(false);
            setSuccess(true);
            setPayrollSuccessResponseData(res.data.result.payRollDetails);
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getPayrolls();
              setPayrollCreate(false);
            }, 2000);
          } else {
            setError(true);
            setSelectedEmployees(null);
            Notification("error", "Error", res.data.message);
            setPayrollErrorResponseData(res.data.result.payRollDetails);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const viewErrorMsg = (errMsg, e) => {
    e.preventDefault();
    setFailedErrMessages(errMsg.messages);
    setFailedMsgModal(true);
  };

  const handleChangeCurrency = (value) => {
    setCurrencies(value);
  };

  const handleChangeCountry = (value) => {
    setCountries(value);
  };

  const currencySelect = [];
  currencyDatas.map((currency) => {
    currencySelect.push(
      <Option key={currency.currency}>{currency.currency}</Option>
    );
  });

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.id} selected>
        {currency.currency}{" "}
        {currency.balance
          ? Intl.NumberFormat("en-US").format(currency.balance)
          : 0}
      </option>
    );
  });

  const countrySelect = [];
  countriesData.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });

  const updatePayroll = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      walletId: currencies,
      countries: countries,
      type,
      endPeriod: convertISO(endPeriod?.slice(0, 10)),
      startPeriod: convertISO(startPeriod?.slice(0, 10)),
      employeeIds: selectedEmployees?.map((d) => d?.employeeId),
      departments: depts,
      levels: levels,
      positions: positions,
      frequency: frequency,
      isScheduled: reoccuringCheck,
      triggerDay: dayMonth.toString(),
      triggerHour: occuringTimeHour.toString(),
      triggerMinute: occuringTimeMin.toString(),
      triggerMonth: triggerMonth.toString(),
      triggerYear: triggerYear.toString(),
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/payrolls/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setRemitNhf("");
          setEndPeriod("");
          setSearchVal("");
          closeListup();
          setRemitTax("");
          setRemitTax("");
          setPayrollCreate(false);
          setSuccess(true);
          setOccuuringTimeHour("");
          setReoccuring(false);
          setTriggerDayOnce("");
          setTriggerYear("");
          setMonthVar("");
          setOccuuringTimeMin("");
          setDayMonth("");
          setFrequency("");
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getPayrolls();
            setPayrollCreate(false);
          }, 2000);
        } else {
          setError(true);
          Notification("error", "Error", res.data.message);
          setPayrollErrorResponseData(res.data.result.payRollDetails);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const getPosition = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    const updatedData = payrollData.map((item) => ({ ...item, checked }));
    setPayrollData(updatedData);
    setListCheck(updatedData);
  };

  const handleCheckboxChange = (id) => {
    const updatedData = payrollData.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setPayrollData(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  const getLevels = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLevelOptions(
            res.data.result?.map((d) => ({
              value: d?.level,
              label: d?.level,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const getDepartment = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if(!selectAll){
      setListCheck([])
    }
  },[selectAll])


  useEffect(() => {
    getPayrolls();
    getPaygroup();
    getChecklists();
    getEmployeeInfo();
    getCurrencies();
    getPosition();
    getDepartment();
    getLevels();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closePayrollAmtModal = () => {
    setpayrollAmtModal(false);
    setType(false);
  };

  const role = sessionStorage.getItem("activeRole");

  const paygroupOptions = paygroupData.map((paygroup) => {
    return <option value={paygroup.id}>{paygroup.groupName}</option>;
  });

  const payrollType = payrollTypes.map((type) => {
    return <option value={type.code}>{type.value?.replace("_", " ")}</option>;
  });

  function onChangeRemitPension(e) {
    setRemitPension(e.target.checked);
  }
  function onChangeRemitTax(e) {
    setRemitTax(e.target.checked);
  }
  function onChangeRemitNhf(e) {
    setRemitNhf(e.target.checked);
  }
  function onChangeStartDate(date, dateString) {
    setStartPeriod(date);
  }
  function onChangeEndDate(date, dateString) {
    setEndPeriod(date);
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${
      page - 1
    }&size=${pageSize}&employerId=${sessionStorage.getItem("employer_id")}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/payrolls/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const payrollData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPayrollData(
            payrollData.map((data) => ({
              id: data.id,
              totalAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalAmount),
              narration: data.narration,
              totalPayableAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalPayableAmount),
              totalDeduction:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalDeduction),
              totalFee:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalFee),
              payGroup: data.payGroup ? data.payGroup : "NA",
              payrollDetails: data.payrollAmount,
              size: data.totalEmployees,
              created_date: data?.createdAt
                ? data?.createdAt?.slice(0, 10)
                : "",
              type: data.type,
              isScheduled: data?.isScheduled,
              currency: data.walletId,
              startPeriod: data.startPeriod
                ? data.startPeriod.slice(0, 10)
                : "",

              endPeriod: data.endPeriod ? data.endPeriod.slice(0, 10) : "",
              fullEndPeriod: data.endPeriod ? data.endPeriod : "",
              fullStartPeriod: data.startPeriod ? data.startPeriod : "",
              status: data.status,
              frequency: data?.frequency,
              triggerDay: data?.triggerDay,
              scheduleStatus: data?.scheduleStatus,
              triggerHour: data?.triggerHour,
              triggerMinute: data?.triggerMinute,
              triggerMonth: data?.triggerMonth,
              triggerYear: data?.triggerYear,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChangeMonth = (date, dateString) => {
    console.log(date, dateString);
    setMonthOccuring(dateString);
  };

  const onChangeTime = (val) => {
    setOccuuringTimeMin(val.$m);
    setOccuuringTimeHour(val.$H);
    setScheduleTime(val);
  };

  const approvePayrollFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/payrolls/approve/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccess(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPayrolls();
          setTimeout(() => {
            setSuccess(false);
            setStatusMsg("");
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setError(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setError(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const declinePayrollFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/payrolls/decline/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessDecline(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPayrolls();
          setTimeout(() => {
            setSuccessDecline(false);
            setStatusMsg("");
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setError(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setError(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const approveStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to Approve this payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        approvePayrollFunc();
      },
      onCancel() {},
    });
  };

  const declineStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to decline this payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        declinePayrollFunc();
      },
      onCancel() {},
    });
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              {!loadPayroll && (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="card-title">Payroll </h1>
                    <button
                      className="btn btn-info "
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={createPayroll}
                    >
                      Create Payroll
                    </button>{" "}
                  </div>

                  <div className="">
                    {listChecks?.length > 0 && (
                      <div style={{ position: "relative" }}>
                        <div className="cl-delete-trash">
                          <div className="d-flex align-items-center">
                            <div
                              className="bord-delete"
                              onClick={onDeleteMultipleEmployees}
                            >
                              {" "}
                              <i
                                style={{ color: "#1fc157" }}
                                className="fa fa-trash"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ position: "relative" }}>
                      <div class="form-check tb-check-head">
                        <input
                          class="form-check-input border-checks"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>{" "}
                    <div style={{ overflowX: "auto" }} className="pb-4">
                      <ReactDatatable
                        config={configTable}
                        records={payrollData}
                        columns={columns}
                        extraButtons={extraButtons}
                      />
                    </div>
                    {payrollData?.length > 0 && (
                      <div className="text-center pagination-part pt-5">
                        <Pagination
                          current={page}
                          total={totalItems}
                          defaultPageSize={10}
                          onChange={pagination}
                          itemRender={itemRender}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {loadPayroll && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div className="text-right d-flex justify-content-end align-items-center">
          <span
            className={
              {
                true: "badge bg-success",
                false: "badge bg-danger",
              }[isScheduledPayroll]
            }
          >
            {isScheduledPayroll ? "SCHEDULED PAYROLL" : ""}
          </span>
        </div>
        <Link to={{ pathname: "/payroll_details", state: { rowId } }}>
          <div style={{ color: "#000000", cursor: "pointer" }}>
            View Payroll Details
          </div>
        </Link>
        <hr />
        {indStatus !== "COMPLETED" && (
          <div>
            <div
              style={{ cursor: "pointer", color: "#000000" }}
              onClick={updatePayrollSwitch}
            >
              Update Payroll
            </div>
            <hr />
          </div>
        )}

        {
          {
            IDLE: (
              <>
                <div
                  style={{ color: "#000000", cursor: "pointer" }}
                  onClick={() => {
                    closeDrawer();
                    if (isScheduledPayroll === true) {
                      setOpenRunPayrollScheduled(true);
                    } else {
                      runPayroll();
                    }
                  }}
                >
                  Run Payroll
                </div>
                {role === "EMPLOYER" && (
                  <>
                    <hr />
                    <div
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={showDeleteConfirm}
                    >
                      Delete Payroll
                    </div>
                    <hr />
                  </>
                )}
              </>
            ),
            PENDING: (
              <>
                {role === "EMPLOYER" && (
                  <>
                    <div
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={approveStatusConfirm}
                    >
                      Approve Payroll
                    </div>
                    <hr />
                    <div
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={declineStatusConfirm}
                    >
                      Decline Payroll
                    </div>

                    <hr />
                  </>
                )}
              </>
            ),
            STARTED: (
              <>
                {role === "EMPLOYER" && (
                  <>
                    <div
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={stopayroll}
                    >
                      Stop Payroll
                    </div>
                    <hr />
                  </>
                )}
              </>
            ),
            PROCESSING: <></>,
            COMPLETED: <></>,
          }[indStatus]
        }
        {isScheduledPayroll &&
          role === "EMPLOYER" &&
          indStatus !== "COMPLETED" && (
            <div style={{ color: "#000000" }} className="text-menu-dark-link">
              <div
                className="text-menu-dark-link"
                style={{ cursor: "pointer", color: "#000000 !important" }}
                onClick={resumePausePayroll.bind(this, "TERMINATE")}
              >
                Terminate Schedule
              </div>
              <hr />
              <div
                className="text-menu-dark-link"
                style={{ cursor: "pointer", color: "#000000 !important" }}
                onClick={() => {
                  setUpdateScheduleModal(true);
                  setVisibleDraw(false);
                }}
              >
                Update Schedule
              </div>
              {indStatus !== "IDLE" && (
                <div>
                  <hr />
                  <div
                    className="text-menu-dark-link"
                    style={{ cursor: "pointer", color: "#000000 !important" }}
                    onClick={resumePausePayroll.bind(
                      this,
                      updateData?.scheduleStatus === "RESUME"
                        ? "PAUSE"
                        : "RESUME"
                    )}
                  >
                    {updateData?.scheduleStatus === "RESUME"
                      ? "Pause Payroll "
                      : "Resume Payroll "}
                  </div>
                </div>
              )}
            </div>
          )}
      </Drawer>
      <Modal
        title={!updateSwitch ? "Create Payroll" : "Update Payroll"}
        visible={payrollCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <DatePicker
                  onChange={onChangeStartDate}
                  value={startPeriod}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Start Date"
                />
              </div>
              <div className="mb-3">
                <DatePicker
                  onChange={onChangeEndDate}
                  value={endPeriod}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="End Date"
                />
              </div>

              <div class="mb-4">
                <label class="form-label">Preferred Wallet Currency</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCurrencies(e.target.value)}
                  value={currencies}
                >
                  <option selected>Select </option>
                  {currencyOptions}
                </select>
              </div>
              <div class="mb-4">
                <label class="form-label">Payroll Type</label>

                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  <option selected>Select</option>
                  {payrollType}
                </select>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div className="mb-3">
                  <DatePicker
                    onChange={onChangeStartDate}
                    defaultValue={dayjs(startPeriod, dateFormat)}
                    style={{
                      width: "100%",
                      height: "3.3rem",
                      borderRadius: "10px",
                    }}
                    placeholder="Start Date"
                  />
                </div>
                <div className="mb-3">
                  <DatePicker
                    onChange={onChangeEndDate}
                    defaultValue={dayjs(endPeriod, dateFormat)}
                    style={{
                      width: "100%",
                      height: "3.3rem",
                      borderRadius: "10px",
                    }}
                    placeholder="End Date"
                  />
                </div>

                <div class="mb-4">
                  <label class="form-label">Preferred Wallet Currency</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCurrencies(e.target.value)}
                    value={currencies}
                  >
                    <option selected>Select </option>
                    {currencyOptions}
                  </select>
                </div>
                <div class="mb-4">
                  <label class="form-label">Payroll Type</label>

                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  >
                    <option selected>Select</option>
                    {payrollType}
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={getPayrollSummary}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title="Run Payroll"
        open={openRunPayrollScheduled}
        footer={false}
        width={500}
        onCancel={() => {
          setOpenRunPayrollScheduled(false);
        }}
      >
        <p className="text-center">
          Are you sure you want to run this payroll ?
        </p>
        <br />
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "red",
                  color: "white",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  setOpenRunPayrollScheduled(false);
                }}
              >
                Cancel{" "}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  color: "white",
                  marginRight: "1rem",
                }}
                onClick={runPayroll.bind(this, false)}
              >
                Run Now{" "}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={runPayroll.bind(this, true)}
              >
                Run At Schedule{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={"Payout Details"}
        visible={payrollDetailsShow}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeListup}
        onClose={closeListup}
        width={"100%"}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card">
              <div class="">
                <div class="row">
                  <div class="col-md-2 col-12">
                    <div class="card pb-2">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-white">Eligible Employees</div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {payrollSummaryData?.eligibleEmployees
                            ? payrollSummaryData.eligibleEmployees?.length
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-12">
                    <div
                      class="card pb-2"
                      style={{ textAlign: "center", padding: "3px" }}
                    >
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          class="pb-3 text-center text-white"
                          style={{ textAlign: "center !important" }}
                        >
                          Uneligible Employees
                        </div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {payrollSummaryData?.uneligibleEmployees
                            ? payrollSummaryData.uneligibleEmployees?.length
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-12">
                    <div class="card  pb-2 stat-widget">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-center text-white">
                          Total Net Salary
                        </div>
                        <div className="text-center h5 pb-3 font-weight-bold">
                          {payrollSummaryData?.currency}
                          {payrollSummaryData?.totalNetSalary
                            ? Intl.NumberFormat("en-US").format(
                                payrollSummaryData.totalNetSalary
                              )
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-12">
                    <div class="card pb-2 stat-widget">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-center text-white">
                          Amount Payable
                        </div>
                        <div className="text-center h5 pb-3 font-weight-bold">
                          {payrollSummaryData?.currency}
                          {payrollSummaryData?.totalPayableAmount
                            ? Intl.NumberFormat("en-US").format(
                                payrollSummaryData.totalPayableAmount
                              )
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-12">
                    <div class="card pb-2 stat-widget">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-center text-white">
                          Total Deduction
                        </div>
                        <div className="text-center h5 pb-3 font-weight-bold">
                          {payrollSummaryData?.currency}
                          {payrollSummaryData?.totalDeduction
                            ? Intl.NumberFormat("en-US").format(
                                payrollSummaryData.totalDeduction
                              )
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-12">
                    <div class="card pb-2 stat-widget">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-center text-white">Total Fee</div>
                        <div className="text-center h5 pb-3 font-weight-bold">
                          {payrollSummaryData?.currency}
                          {payrollSummaryData?.totalFee
                            ? Intl.NumberFormat("en-US").format(
                                payrollSummaryData.totalFee
                              )
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) => setReoccuring(e.target?.checked)}
                      checked={reoccuringCheck}
                    />
                    <label
                      class="form-check-label font-weight-bold"
                      for="flexCheckDefault"
                    >
                      <i
                        class="fa fa-recycle pl-2"
                        style={{ color: "#000000" }}
                      ></i>{" "}
                      Schedule Payroll
                    </label>
                  </div>
                  {updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={updatePayroll}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Update Payroll"
                      )}
                    </button>
                  )}
                  {!updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={addPayroll}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Create Payroll"
                      )}
                    </button>
                  )}
                </div>

                {reoccuringCheck && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <label>Frequency</label>
                        <div className="w-100">
                          <Select
                            mode="single"
                            value={frequency}
                            style={{
                              width: "150px",
                              marginRight: "1rem",
                            }}
                            onChange={(val) => {
                              setFrequency(val);
                              setScheduleTime("00:00");
                              setDayMonth("");
                            }}
                            options={frequencyTypes?.map((d) => ({
                              value: d?.value,
                              label: d?.code,
                            }))}
                          />
                        </div>
                      </div>

                      {frequency === "MONTHLY" && (
                        <div className=",t-3">
                          <label>Day of Month</label>
                          <div className="w-100">
                            <Select
                              mode="single"
                              value={dayMonth}
                              style={{
                                width: "150px",
                                marginRight: "1rem",
                              }}
                              onChange={(val) => {
                                setDayMonth(val);
                              }}
                              options={[
                                { label: "1", value: "1" },
                                { label: "2", value: "2" },
                                { label: "3", value: "3" },
                                { label: "4", value: "5" },
                                { label: "6", value: "6" },
                                { label: "7", value: "7" },
                                { label: "8", value: "8" },
                                { label: "9", value: "9" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                                { label: "13", value: "13" },
                                { label: "14", value: "14" },
                                { label: "15", value: "15" },
                                { label: "16", value: "16" },
                                { label: "17", value: "17" },
                                { label: "18", value: "18" },
                                { label: "19", value: "19" },
                                { label: "20", value: "20" },
                                { label: "21", value: "21" },
                                { label: "22", value: "22" },
                                { label: "23", value: "23" },
                                { label: "24", value: "24" },
                                { label: "25", value: "25" },
                                { label: "26", value: "26" },
                                { label: "27", value: "27" },
                                { label: "28", value: "28" },
                                { label: "29", value: "29" },
                                { label: "30", value: "30" },
                                { label: "31", value: "31" },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                      {frequency === "WEEKLY" && (
                        <div className=";t-3">
                          <label>Day of Week</label>
                          <div className="w-100">
                            <Select
                              mode="single"
                              value={dayMonth}
                              style={{
                                width: "150px",
                                marginRight: "1rem",
                              }}
                              onChange={(val) => {
                                setDayMonth(val);
                              }}
                              options={[
                                { label: "Monday", value: "MONDAY" },
                                { label: "Tuesday", value: "TUESDAY" },
                                { label: "Wednesday", value: "WEDNESDAY" },
                                { label: "Thursday", value: "THURSDAY" },
                                { label: "Friday", value: "FRIDAY" },
                                { label: "Saturday", value: "SATURDAY" },
                                { label: "Sunday", value: "SUNDAY" },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                      {frequency === "ONCE" && (
                        <div className="mt-3">
                          <label>Select Day </label>
                          <div className="w-100">
                            <DatePicker
                              onChange={onChnageDateOnce}
                              value={
                                triggerDayOnce
                                  ? dayjs(triggerDayOnce, dateFormat)
                                  : ""
                              }
                              style={{
                                width: "150px",
                                height: "3.1rem",
                                borderRadius: "10px",
                                marginRight: "1rem",
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {frequency && (
                        <div className={frequency === "ONCE" ? "pt-3" : "pt-2"}>
                          <label>Select Time of Day</label>
                          <div className="w-100">
                            <TimePicker
                              onChange={onChangeTime}
                              showSecond={false}
                              value={dayjs(scheduledTime, "HH:mm")}
                              format={"HH:mm"}
                              style={{
                                width: "150px",
                                height: "3.1rem",
                                borderRadius: "10px",
                                marginRight: "1rem",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <br />
                <Tabs
                  defaultActiveKey="1"
                  items={itemsTab}
                  onChange={onChangeTab}
                />
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={searchVal}
                        id="exampleInputFirstName"
                        placeholder="Search Employee..."
                        onChange={handleSearch}
                      />
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="w-100 mr-3">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Departments"
                            onChange={(val) => {
                              setDepts(val);
                            }}
                            options={departmentOptions}
                          />
                        </div>
                        <div className="w-100 mr-3">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Positions"
                            onChange={(val) => {
                              setPos(val);
                            }}
                            options={positionOptions}
                          />
                        </div>
                        <div className="w-100">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Employee Level"
                            onChange={(val) => {
                              setevels(val);
                            }}
                            options={levelOptions}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{ background: "#1FC157", color: "white" }}
                            onClick={getPayrollSummarySearch}
                          >
                            {loadSearch ? "Filtering..." : "Filter"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {activeTabPayroll === "1" && (
                  <DataTable
                    value={filteredpayrollEligibleData}
                    tableStyle={{ minWidth: "30rem" }}
                    paginator
                    rows={50}
                    rowsPerPageOptions={[50, 100, 200]}
                    selectionMode={"checkbox"}
                    selection={selectedEmployees}
                    onSelectionChange={(e) => {
                      if (e.value) {
                        setSelectedEmployees(e.value);
                        // getPayrollSummarySearch();
                      }
                    }}
                    dataKey="employeeId"
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                    ></Column>

                    <Column
                      field="employeeCode"
                      header="Employee Code"
                    ></Column>
                    <Column
                      field="employeeName"
                      header="Employee Name"
                    ></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="netSalary" header="Net Salary"></Column>
                    <Column field="deduction" header="Deduction"></Column>
                    <Column
                      field="payableAmount"
                      header="Amount Payable"
                    ></Column>
                    <Column field="fee" header="Fee"></Column>
                  </DataTable>
                )}
                {activeTabPayroll === "2" && (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Net Salary</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payrollSummaryData?.uneligibleEmployees?.map(
                          (data) => {
                            return (
                              <tr>
                                <td>{data?.employeeCode}</td>
                                <td>
                                  {data?.firstName + " " + data?.lastName}
                                </td>
                                <td>{data?.workEmail}</td>

                                <td>
                                  {" "}
                                  {payrollSummaryData?.currency}{" "}
                                  {data?.monthlyNetSalary
                                    ? Intl.NumberFormat("en-US").format(
                                        data?.monthlyNetSalary
                                      )
                                    : 0}
                                </td>
                                <td>
                                  <button
                                    onClick={viewErrorMsg.bind(this, data)}
                                    style={{
                                      background: "red",
                                      color: "white",
                                      border: "1px solid red",
                                    }}
                                    size="small"
                                  >
                                    View Error
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <br />
                <div className="d-flex justify-content-end ">
                  {updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={updatePayroll}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Update Payroll"
                      )}
                    </button>
                  )}
                  {!updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={addPayroll}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Create Payroll"
                      )}
                    </button>
                  )}
                </div>
              </div>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={type === "amount" ? "Payroll Amounts" : "Payroll Fees "}
        visible={payrollAmtModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closePayrollAmtModal}
      >
        {type === "amount" ? (
          <div>
            {payrollAmt.map((payrollAmt) => {
              return (
                <ul>
                  <li>
                    {payrollAmt.currency} {payrollAmt.amount}
                  </li>
                </ul>
              );
            })}
          </div>
        ) : (
          <div>
            {payrollAmt.map((payrollFee) => {
              return (
                <ul>
                  <li>
                    {payrollFee.currency} {payrollFee.fee}
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </Modal>

      <Modal
        title={false}
        visible={payrollResponse}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={"100%"}
        onCancel={closePayrollResponse}
      >
        <div>
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
                <Tabs defaultActiveKey={activeTab} onChange={callback}>
                  <TabPane tab="Success" key="1" style={{ color: "green" }}>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Salary</th>
                            <th scope="col">Deduction</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payrollSuccessResponseData?.map((data) => {
                            return (
                              <tr>
                                <td>{data?.employeeName}</td>
                                <td>
                                  {data?.salaryCurrency}{" "}
                                  {Intl.NumberFormat("en-US").format(
                                    data?.postNetSalary
                                  )}
                                </td>
                                <td>
                                  {data?.salaryCurrency}{" "}
                                  {Intl.NumberFormat("en-US").format(
                                    data?.monthlyDeduction
                                  )}{" "}
                                </td>
                                <td>{data?.status}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>

                  <TabPane tab="Failed" key="2">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Salary</th>
                            <th scope="col">Deduction</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payrollErrorResponseData.map((data) => {
                            return (
                              <tr>
                                <td>{data.employeeName}</td>
                                <td>
                                  {data.salaryCurrency}{" "}
                                  {Intl.NumberFormat("en-US").format(
                                    data.preNetSalary
                                  )}
                                </td>
                                <td>
                                  {data.salaryCurrency}{" "}
                                  {Intl.NumberFormat("en-US").format(
                                    data.monthlyDeduction
                                  )}{" "}
                                </td>
                                <td>{data.status}</td>
                                <td>
                                  <button
                                    onClick={viewErrorMsg.bind(
                                      this,
                                      data.messages
                                    )}
                                    style={{
                                      background: "red",
                                      color: "white",
                                      border: "1px solid red",
                                    }}
                                    size="small"
                                  >
                                    View Error
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Error Messages"
        visible={failedMsgModal}
        okText="Skip"
        onCancel={closeFailedMsg}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          {failedErrMsgs.map((msg) => {
            return (
              <div className="pb-2">
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        title="Update Schedule"
        visible={updateScheduleModal}
        okText="Skip"
        onCancel={() => {
          setUpdateScheduleModal(false);
        }}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          <div className="">
            <div className="">
              <label>Frequency</label>
              <Select
                mode="single"
                value={frequency}
                style={{
                  width: "100%",
                  marginRight: "1rem",
                }}
                onChange={(val) => {
                  setFrequency(val);
                  setScheduleTime("00:00");
                  setDayMonth("");
                }}
                options={frequencyTypes?.map((d) => ({
                  value: d?.value,
                  label: d?.code,
                }))}
              />
            </div>
          </div>
          <br />
          {frequency === "MONTHLY" && (
            <div className="">
              <label>Day of Month</label>
              <div className="w-100">
                <Select
                  mode="single"
                  value={dayMonth}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setDayMonth(val);
                  }}
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                    { label: "8", value: "8" },
                    { label: "9", value: "9" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                    { label: "13", value: "13" },
                    { label: "14", value: "14" },
                    { label: "15", value: "15" },
                    { label: "16", value: "16" },
                    { label: "17", value: "17" },
                    { label: "18", value: "18" },
                    { label: "19", value: "19" },
                    { label: "20", value: "20" },
                    { label: "21", value: "21" },
                    { label: "22", value: "22" },
                    { label: "23", value: "23" },
                    { label: "24", value: "24" },
                    { label: "25", value: "25" },
                    { label: "26", value: "26" },
                    { label: "27", value: "27" },
                    { label: "28", value: "28" },
                    { label: "29", value: "29" },
                    { label: "30", value: "30" },
                    { label: "31", value: "31" },
                  ]}
                />
              </div>
              <br />
            </div>
          )}
          {frequency === "WEEKLY" && (
            <div className="">
              <label>Day of Week</label>
              <div className="w-100">
                <Select
                  mode="single"
                  value={dayMonth}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setDayMonth(val);
                  }}
                  options={[
                    { label: "Monday", value: "MONDAY" },
                    { label: "Tuesday", value: "TUESDAY" },
                    { label: "Wednesday", value: "WEDNESDAY" },
                    { label: "Thursday", value: "THURSDAY" },
                    { label: "Friday", value: "FRIDAY" },
                    { label: "Saturday", value: "SATURDAY" },
                    { label: "Sunday", value: "SUNDAY" },
                  ]}
                />
              </div>
              <br />
            </div>
          )}
          {frequency === "ONCE" && (
            <div className="mb-3">
              <label>Select Day </label>
              <div className="w-100">
                <DatePicker
                  onChange={onChnageDateOnce}
                  value={
                    triggerDayOnce ? dayjs(triggerDayOnce, dateFormat) : ""
                  }
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          )}
          <div className="mb-3">
            <label>Select Time of Day</label>
            <div className="w-100">
              <TimePicker
                onChange={onChangeTime}
                showSecond={false}
                value={dayjs(scheduledTime, "HH:mm")}
                format={"HH:mm"}
                style={{
                  width: "100%",
                  height: "3.1rem",
                  borderRadius: "10px",
                  marginRight: "1rem",
                }}
              />
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={updatePayrollSchedule}
            >
              {loaUpdateSchedule ? (
                <ThreeDots
                  visible={loaUpdateSchedule}
                  height="20"
                  width="50"
                  color="#ffffff"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Update "
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={onboardChecklist}
        okText="Skip"
        maskClosable={false}
        footer={false}
        width={600}
        onCancel={onCancelOnboardChecklist}
        onOk={onCancelOnboardChecklist}
      >
        <div className="container">
          <div className="check-area pt-2">
            <div className="container">
              <h4 className="text-center" style={{ color: "#1FC157" }}>
                Welcome to SalarioPay!
              </h4>

              <hr />
              <div>
                <h5 className="text-center pt-3">Onboarding Checklist</h5>
                <p className="text-center">
                  Kindly complete the checklist below to get started
                </p>
              </div>

              <div className="check-area pt-2">
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.billEnabled
                        ? userChecklists.billEnabled
                        : ""
                    }
                  >
                    Fund account to run payroll, benefits, pension, and taxes{" "}
                  </Checkbox>
                  <Link to={{ pathname: "/accounts" }}>
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Fund
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.employeeOnboard
                        ? userChecklists.employeeOnboard
                        : ""
                    }
                  >
                    Onboard employee in order to generate payroll{" "}
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/employee_onboarding",
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Onboard
                    </Button>
                  </Link>
                </div>
                <br />
                <hr />
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={onCancelOnboardChecklist}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "75px",
                    }}
                    size="small"
                  >
                    Skip{" "}
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
