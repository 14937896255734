import React, { useState, useEffect } from "react";
import Tabs from "./tab";
import Layout from "../../../containers/Layout";
import { useForm, Controller } from "react-hook-form";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useHistory } from "react-router-dom";
import { Spin, Select, notification } from "antd";
import { country } from "faker/lib/locales/az/address";
export default function EmployeeInfo(props) {
  const onboardingId = sessionStorage?.getItem("onboardID");
  const [load, setLoad] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");
  const [paymentCountry, setPaymentCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [bankName, setBankName] = useState("");
  const [currencyDatas, setCurrencyDatas] = useState([]);
  const [accountNo, setAccountNo] = useState("");
  const [loadBank, setLoadBank] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [pfaRsaPin, setPfaRsaPin] = useState("");
  const [bankLists, setBankLists] = useState([]);
  const [pfaLists, setPfaData] = useState([]);
  const [taxStateLists, setTaxList] = useState([]);
  const [pfaName, setPfaName] = useState("");
  const [taxState, setTaxState] = useState("");
  const [paymentTypeData, setPaymentTypeData] = useState([]);
  const [tin, setTin] = useState("");
  let info;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const getInfo = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/onboarding-link/invite/${onboardingId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          info = res.data.result;
          setPaymentCountry(info?.paymentCountry);
          setPaymentOption(info?.paymentOption);
          setCurrency(info?.paymentCurrency);
          setBankName(info?.institutionName);
          setAccountName(info?.accountName);
          setStatusVal(info?.reviewStatus);
          setAccountNo(info?.accountIdentifier);
          setTaxState(info.tinState);
          setTin(info?.tin);
          setPfaName(info?.pfaName);
          setPfaRsaPin(info?.pfaCode);
          getBanksByCountry(info.country);
          getPfa(info.country);
          getTaxStates(info.country);

          setValue("paymentCountry", info?.paymentCountry);
          setValue("paymentOption".info?.paymentOption);
          setValue("currency", info?.paymentCurrency);
          setValue("bankCode", info?.institutionName);
          setValue("accountNo", info?.accountIdentifier);
          setValue("tin", info?.tin);
          setValue("pfaName", info?.pfaName);
          setValue("pfaRsaPin", info?.pfaRsaPin);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const onSearch = (value) => {};

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const paymentOptions = paymentTypeData.map((payment) => {
    return (
      <option value={payment.code} key={payment.id}>
        {payment.name}
      </option>
    );
  });

  const countryOptions = countryData.map((countries) => {
    return (
      <option value={countries.code} key={countries.code}>
        {countries.name}
      </option>
    );
  });

  const getBankCode = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.value) {
      return bank?.value;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.value;
    }
  };

  const countryChange = (e) => {
    if (e.target.value) {
      setPaymentCountry(e.target.value);
      getPfa(e.target.value);
      getTaxStates(e.target.value);
      getBanksByCountry(e.target.value);
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`)
      .then((res) => {
        if (res.data.status) {
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/PFA/${country}`)
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;
          const index = 8;
          const value = {
            createdAt: "2024-06-05T07:08:57.837+00:00",
            updatedAt: "2024-06-05T07:08:57.837+00:00",
            id: null,
            country: "NG",
            code: "Select",
            valuue: "NONE",
          };
          const newArr = [];
          for (let i = 0; i < arr.length; i++) {
            if (i === index) {
              newArr.push(value);
            }
            newArr.push(arr[i]);
          }

          setPfaData(
            newArr?.map((d) => ({
              value: d?.code,
              label: d?.value,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCurrencies = () => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered`)
      .then((res) => {
        if (res.data.status) {
          setCurrencyDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.currencyCode} key={currency.currencyCode}>
        {currency.currencyName}
      </option>
    );
  });

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`)
      .then((res) => {
        if (res.data.status) {
          setTaxList(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const fetchAccName = (e) => {
    if (bankName && paymentCountry) {
      setLoadBank(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: getBankCode(bankName),
            accountNumber: e.target.value,
            countryCode: paymentCountry,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadBank(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };
  const getPaymentType = () => {
    axios
      .get(`${config.baseUrl}/v1/payment-options/filtered`)
      .then((res) => {
        if (res.data.status) {
          setPaymentTypeData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getInfo();
    getPaymentType();
    getCounties();
    getCurrencies();
  }, []);

  const goNext = (data) => {
    const datae = {
      paymentOption: paymentOption,
      paymentCountry: paymentCountry,
      currency: currency,
      accountName: accountName,
      accountNo: accountNo,
      taxState: taxState,
      bankName: bankName ? bankName : data?.bankCode,
      tin: tin,
      pfaRsaPin: pfaRsaPin,
      pfaName: pfaName,
    };
    var dataStringify = JSON?.stringify(datae);
    sessionStorage?.setItem("emp_paymentlInfo", dataStringify);
    history.push("/employee-work-info");
  };

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {load && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {loadBank && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {!load && (
              <div>
                <div>
                  <div>
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Payment Method
                              </label>

                              <select
                                class="form-select"
                                name="paymentFunc"
                                value={paymentOption}
                                {...register("payOption", {
                                  required: "Payment Option is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-label="Default select example"
                                onChange={(e) =>
                                  setPaymentOption(e.target?.value)
                                }
                              >
                                <option selected>Select </option>
                                {paymentOptions}
                              </select>
                              {errors.payOption && (
                                <span className="validate-error">
                                  {errors.payOption.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Payment Country
                              </label>
                              <select
                                class="form-select"
                                name="paymentFunc"
                                value={paymentCountry}
                                aria-label="Default select example"
                                onChange={countryChange}
                                {...register("paymentCountry", {
                                  required: "Payment Country is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                              >
                                <option selected>Select </option>
                                {countryOptions}
                              </select>
                              {errors.paymentCountry && (
                                <span className="validate-error">
                                  {errors.paymentCountry.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Currency
                              </label>
                              <select
                                class="form-select"
                                name="paymentFunc"
                                value={currency}
                                {...register("currency", {
                                  required: "Payment Currency is required",
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-label="Default select example"
                                onChange={(e) => setCurrency(e.target?.value)}
                              >
                                <option selected>Select </option>
                                {currencyOptions}
                              </select>
                              {errors.currency && (
                                <span className="validate-error">
                                  {errors.currency.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Bank Name
                              </label>
                              <Controller
                                name="bankCode"
                                control={control}
                                {...register("bankCode", {
                                  required: "Bank is required",
                                })}
                                render={({ field }) => (
                                  <Select
                                    value={bankName}
                                    style={{
                                      width: "100%",
                                      height: "70px !important",
                                      borderRadius: "10px !important",
                                      marginBottom: ".8rem",
                                    }}
                                    onChange={(val) => {
                                      setBankName(val);
                                    }}
                                    options={bankLists}
                                    showSearch
                                    placeholder="Select a Bank"
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    disabled={
                                      statusVal === "APPROVED" ? true : false
                                    }
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  />
                                )}
                              />
                              {errors.bankCode && (
                                <span className="validate-error">
                                  {errors.bankCode.message}
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Account Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                maxLength={10}
                                {...register("accountNo", {
                                  required: "Account Number is required",
                                  maxLength: {
                                    value: 10,
                                    message: `Account Number cannnot be more than 10 digits`,
                                  },
                                  minLength: {
                                    value: 10,
                                    message: `Account Number cannnot be more than 10 digits`,
                                  },
                                })}
                                onChange={(e) => setAccountNo(e.target?.value)}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                aria-describedby="emailHelp"
                                onBlur={fetchAccName}
                                value={accountNo}
                              />
                              {errors.accountNo && (
                                <span className="validate-error">
                                  {errors.accountNo.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Account Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={accountName}
                                onChange={(e) =>
                                  setAccountName(e.target?.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                PFA{" "}
                              </label>

                              <div>
                                <Select
                                  showSearch
                                  value={pfaName}
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px !important",
                                  }}
                                  placeholder="Search to Select"
                                  disabled={
                                    statusVal === "APPROVED" ? true : false
                                  }
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  onChange={(val) => {
                                    if (val !== "Select") {
                                      setPfaName(val);
                                      setPfaRsaPin("");
                                    } else {
                                      setPfaName("");
                                      setPfaRsaPin("");
                                    }
                                  }}
                                  options={pfaLists}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                PFA RSA Pin
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                {...register("pfaRsaPin", {
                                  required: pfaName
                                    ? "Pfa Rsa Pin is required"
                                    : false,
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                value={pfaRsaPin}
                                onChange={(e) => setPfaRsaPin(e.target?.value)}
                              />
                              {errors.pfaRsaPin && (
                                <span className="validate-error">
                                  {errors.pfaRsaPin.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Tax State
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={taxState}
                                {...register("taxState", {
                                  required: false,
                                  onChange: (e) => {
                                    if (e.target?.value !== "Select") {
                                      setTaxState(e.target.value);
                                      setTin("");
                                    } else {
                                      setTaxState("");
                                      setTin("");
                                    }
                                  },
                                  value: taxState,
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                name="taxState"
                                onChange={(e) => setTaxState(e.target.value)}
                              >
                                <option selected value={"Select"}>
                                  Select
                                </option>
                                {taxStateLists.map((taxState) => {
                                  return <option>{taxState.value}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Tax ID
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                {...register("tin", {
                                  required: taxState
                                    ? "Tin  is required"
                                    : false,
                                })}
                                disabled={
                                  statusVal === "APPROVED" ? true : false
                                }
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => setTin(e.target?.value)}
                                value={tin}
                              />
                              {errors.tin && (
                                <span className="validate-error">
                                  {errors.tin.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push("/employee-personal-info");
                            }}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                            onClick={handleSubmit(goNext)}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
