import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import Location from "../../../containers/Location/addressViewContact";
import { ThreeDots } from "react-loader-spinner";
import Location2 from "../../../containers/Location/addressViewEmployeeNextofKin";
import { notification, Spin, Select } from "antd";
export default function EmployeeInfo({
  setTabs,
  bankLists,
  pfaData,
  taxData,
  countryData,
  gotBackDefault,
  preloadData,
  info,
}) {
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");

  const [bankSwitch, setBankSwitch] = useState(true);
  const [paymentOption, setPaymentOption] = useState("");
  const [paymentTypeData, setPaymentTypeData] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [pfaPin, setPfaPin] = useState("");
  const [taxid, setTaxId] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [rsaPin, setRsaPin] = useState("");
  const [pfa, setPFA] = useState("");
  const [pfaOptionsLists, setPfaOptions] = useState([]);
  const [bankCode, setBankCode] = useState("");
  const [taxStateData, setTaxData] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const [paymentCountry, setPaymentCountry] = useState("");
  const [loadBank, setLoadBank] = useState(false);
  const [currencyDatas, setCurrencyDatas] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
    }
  };
  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getCurrencies = () => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered`)
      .then((res) => {
        if (res.data.status) {
          setCurrencyDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;
        case "taxId":
          setTaxId(truncatedValue);
          break;
        case "accNo":
          setAccountNum(truncatedValue);

        default:
          break;
      }
    }
  };

  const fetchAccName = () => {
    if (bankCode && accountNum && paymentCountry) {
      setLoadBank(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bankCode,
            accountNumber: accountNum,
            countryCode: paymentCountry,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadBank(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
            setValue("accountName", res.data.result.accountName);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  };

  useEffect(() => {
    getCurrencies();
    if (accountNum?.length === 10 && !info?.accountName) {
      fetchAccName();
    }
  }, [accountNum]);

  const countryOptions = countryData.map((countries) => {
    return (
      <option value={countries.code} key={countries.code}>
        {countries.name}
      </option>
    );
  });

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const setPaymentFunc = (e) => {
    setPaymentOption(e.target.value);
    if (e.target.value === "BANK_ACCOUNT") {
      setBankSwitch(true);
    }
  };

  const paymentOptions = paymentTypeData.map((payment) => {
    return (
      <option value={payment.code} key={payment.id}>
        {payment.name}
      </option>
    );
  });

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.currencyCode} key={currency.currencyCode}>
        {currency.currencyName}
      </option>
    );
  });

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const completePayment = (data) => {
    setTabs("3");
    var dataStringify = JSON?.stringify(data);
    sessionStorage?.setItem("paymentInfo", dataStringify);
  };

  const getPaymentType = () => {
    axios
      .get(`${config.baseUrl}/v1/payment-options/filtered`)
      .then((res) => {
        if (res.data.status) {
          setPaymentTypeData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getCurrencies();
    getPaymentType();
  }, []);

  useEffect(() => {
    if (info?.id) {
      setPaymentOption(
        info?.paymentFunc ? info?.paymentFunc : info?.paymentOption
      );
      setPaymentCountry(info?.paymentCountry);
      setCurrencyCode(
        info?.currencyCode ? info.currencyCode : info?.paymentCurrency
      );
      setBankCode(info?.bankCode ? info?.bankCode : info?.institutionName);
      setAccountName(info?.accountName)
      setAccountNum(
        info?.accountNum ? info?.accountNum : info?.accountIdentifier
      );
      setPFA(info?.pfa ? info?.pfa : info?.pfaName);
      setRsaPin(info?.pfaPin ? info?.pfaPin : info?.pfaRsaPin);
      setTaxState(info?.tinState);
      setTaxId(info?.tin);
      setValue('paymentFunc', info?.paymentOption)
      setValue('paymentCountry', info?.paymentCountry)
      setValue('currencyCode', info?.paymentCurrency)
      setValue('bankCode', info?.institutionName)
      setValue('accountNum', info?.accountIdentifier)
      setValue('pfa', info?.pfa ? info?.pfa : info?.pfaCode)
      setValue('pfaPin', info?.pfaPin ? info?.pfaPin : info?.pfaRsaPin)
      setValue('taxState', info?.tinState)
      setValue('taxid', info?.taxid ? info?.taxid : info?.tin)
    } else {
    }
  }, [info]);

  useEffect(() => {
    if (preloadData?.paymentFunc) {
      setPaymentOption(preloadData?.paymentFunc);
      setPaymentCountry(preloadData?.paymentCountry);
      setCurrencyCode(preloadData?.currencyCode);
      setBankCode(preloadData?.bankCode);
      setAccountNum(preloadData?.accountNum);
      setPFA(preloadData?.pfa);
      setRsaPin(preloadData?.pfaPin);
      setTaxState(preloadData?.taxState);
      setTaxId(preloadData?.taxid);


      setValue('paymentFunc', preloadData?.paymentOption)
      setValue('paymentCountry', preloadData?.paymentCountry)
      setValue('currencyCode', preloadData?.currencyCode)
      setValue('bankCode', preloadData?.bankCode)
      setValue('accountNum', preloadData?.accountNum)
      setValue('pfa', preloadData?.pfa)
      setValue('pfaPin', preloadData?.pfaPin)
      setValue('taxState', preloadData?.taxState)
      setValue('taxid', preloadData?.taxid)


    } else {
    }
  }, []);

  console.log(errors);

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-6 col-12">
            <div class="mb-3">
              <label for="floatingInput" className="form-label">
                Payment Method    <span className="text-danger">
                    *
                  </span>
              </label>
              <select
                class="form-select"
                name="paymentFunc"
                value={paymentOption}
                aria-label="Default select example"
                {...register("paymentFunc", {
                  required:
                    paymentTypeData?.length > 0
                      ? "Payment Method is required!"
                      : false,
                  onChange: setPaymentFunc,
                  value: paymentOption,
                })}
              >
                <option selected>Select </option>
                {paymentOptions}
              </select>
              {errors.paymentFunc && (
                <span className="validate-error">
                  {errors.paymentFunc.message}
                </span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="floatingInput" className="form-label">
                Country    <span className="text-danger">
                    *
                  </span>
              </label>
              <select
                class="form-select"
                name="country"
                value={paymentCountry}
                aria-label="Default select example"
                {...register("paymentCountry", {
                  required: "Payment Country is required!",
                  onChange: (e) => setPaymentCountry(e.target?.value),
                  value: paymentCountry,
                })}
              >
                <option selected>Select</option>
                {countryOptions}
              </select>
              {errors.paymentCountry && (
                <span className="validate-error">
                  {errors.paymentCountry.message}
                </span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="floatingInput" className="form-label">
                Currency    <span className="text-danger">
                    *
                  </span>
              </label>
              <select
                class="form-select"
                value={currencyCode}
                name="currencyCode"
                aria-label="Default sselect example"
                {...register("currencyCode", {
                  required: "Currency is required!",
                  onChange: (e) => setCurrencyCode(e.target.value),
                  value: currencyCode,
                })}
              >
                <option selected value="">
                  Select{" "}
                </option>
                {currencyOptions}
              </select>
              {errors.currencyCode && (
                <span className="validate-error">
                  {errors.currencyCode.message}
                </span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="floatingInput" className="form-label">
                Bank    <span className="text-danger">
                    *
                  </span>
              </label>
              <Controller
                name="bankCode"
                control={control}
                {...register("bankCode", {
                  required: "Bank is required",
                })}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={bankCode}
                    style={{
                      width: "100%",
                      height: "70px !important",
                      borderRadius: "10px !important",
                      marginBottom: ".8rem",
                    }}
                    onChange={(val) => {
                      setBankCode(val);
                      setValue("bankCode", val);
                    }}
                    options={bankLists}
                    showSearch
                    placeholder="Select a Bank"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={filterOption}
                  />
                )}
              />

              <br />
              {errors.bankCode && (
                <span className="validate-error mt-3">
                  {errors.bankCode.message}
                </span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div className="mb-3">
              <label for="floatingInput" className="form-label">
                Account Number    <span className="text-danger">
                    *
                  </span>
              </label>
              <input
                type="text"
                name="accountNum"
                className="form-control"
                value={accountNum}
                // onChange={(e) => setAccountNum(e.target.value)}
                {...register("accountNum", {
                  required: "Account Number is required",
                  maxLength: {
                    value: 10,
                    message: "Account Number must be 10 digits",
                  },
                  minLength: {
                    value: 10,
                    message: "Account Number must be 10 digits",
                  },
                  onChange: handleChangeNumber.bind(this, "accNo", 10),

                  value: accountNum,
                })}
              />
              {errors.accountNum && (
                <span className="validate-error">
                  {errors.accountNum.message}
                </span>
              )}
              {loadBank && (
                <ThreeDots
                  visible={loadBank}
                  height="40"
                  width="40"
                  color="#4fa94d"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              )}
            </div>
          </div>
          {accountName && (
            <div class="col-sm-12 col-md-6">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Account Name    <span className="text-danger">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="pfaPin"
                  value={accountName}
                  {...register("accountName", {
                    required: false,
                    value: accountName,
                  })}
                  id="exampleInputEmail1"
                  disabled
                  aria-describedby="emailHelp"
                />
              </div>
            </div>
          )}
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                PFA
              </label>

              <Controller
                name="pfa"
                control={control}
                {...register("pfa", {
                  required: false
                })}
                render={({ field }) => (
                  <Select
                  showSearch
                  value={pfa}
                  style={{
                    width: "100%",
                    borderRadius: "10px !important",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(val) => {
                    if (val !== "Select") {
                      setPFA(val);
                      setPfaPin("");
                      setValue('pfa',val)
                    } else {
                      setPFA("");
                      setPfaPin("");
                    }
                  }}
                  options={pfaData}
                />
                )}
              />

             
              {errors.pfa && (
                <span className="validate-error">{errors.pfa.message}</span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                PFA RSA Pin
              </label>
              <input
                type="text"
                class="form-control"
                name="pfaPin"
                value={rsaPin}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                {...register("pfaPin", {
                  required: pfa ? "PFA RSA Pin is required!" : false,
                  onChange: handleChangeNumber.bind(this, "pfaPin", 15),
                  value: rsaPin,
                })}
              />
              {errors.pfaPin && (
                <span className="validate-error">{errors.pfaPin.message}</span>
              )}
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Tax State
              </label>
              <select
                class="form-select"
                aria-label="Default select example"
                value={taxState}
                name="taxState"
                // onChange={(e) => setTaxState(e.target.value)}
                {...register("taxState", {
                  required: false,
                  onChange: (e) => {
                    if (e.target?.value !== "Select") {
                      setTaxState(e.target.value);
                      setTaxId("");
                    } else {
                      setTaxState("");
                      setTaxId("");
                    }
                  },
                  value: taxState,
                })}
              >
                <option selected value={"Select"}>
                  Select
                </option>
                {taxData.map((taxState) => {
                  return <option>{taxState.value}</option>;
                })}
              </select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Tax ID
              </label>
              <input
                type="text"
                class="form-control"
                value={taxid}
                name="taxid"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                {...register("taxid", {
                  required: taxState ? "Tax ID is required!" : false,
                  onChange: handleChangeNumber.bind(this, "taxId", 10),

                  value: taxid,
                })}
              />
              {errors.taxid && (
                <span className="validate-error">{errors.taxid.message}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <button
          type="submit"
          class="btn btn-primary"
          style={{
            background: "#1FC157",
            marginTop: "1rem",
            color: "white",
          }}
          onClick={() => {
            gotBackDefault();
          }}
        >
          Previous
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          style={{
            background: "#1FC157",
            marginTop: "1rem",
            color: "white",
          }}
          onClick={handleSubmit(completePayment)}
        >
          Next
        </button>
      </div>
    </>
  );
}
