import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Modal,
  DatePicker,
  notification,
  Pagination,
  Select,
  Tabs,
  Checkbox,
} from "antd";
import axios from "axios";
import moment from "moment";
import config from "../../../config";
const { Option } = Select;
const dateFormat = "YYYY/MM/DD";
const { TabPane } = Tabs;
export default function Tax() {
  const [taxData, settaxData] = useState([]);
  const [taxListData, setTaxData] = useState([]);
  const [taxCreate, settaxCreate] = useState(false);
  const [remitNhf, setRemitNhf] = useState(true);
  const [onboardChecklist, setOnboardChecklist] = useState(false);
  const [remittax, setRemittax] = useState(true);
  const [remitTax, setRemitTax] = useState(true);
  const [userChecklists, setUserChecklist] = useState({});
  const [endPeriod, setEndPeriod] = useState("");
  const [startPeriod, setStartPeriod] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [taxResponse, settaxResponse] = useState(false);
  const [taxSuccessResponseData, settaxSuccessResponseData] = useState([]);
  const [taxErrorResponseData, settaxErrorResponseData] = useState([]);
  const [failedMsgs, setFailedMsgs] = useState([]);
  const [failedMsgModal, setFailedMsgModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [taxAmtModal, settaxAmtModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [taxStatus, settaxStatus] = useState("");
  const [paygroupData, setPaygroupData] = useState([]);
  const [paygroup, setPaygroup] = useState("");
  const [taxAmt, settaxAmt] = useState([]);
  const [currencyDatas, setCurrenciesDatas] = useState([]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [countriesData, setCountriesDatas] = useState([]);
  const [activeTab, setActiveTab] = useState(false);

  const [taxState, setTaxState] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");


  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setVisibleDraw(true);
    setCountry(record.country);
    setTaxState(record.state)
    setCurrency(record.currency);
    setStartPeriod(record.period);
    getTaxStates(record.country);

  };

  function callback(key) {
  }

  const showtaxDetails = (record, type, e) => {
    e.preventDefault();
    setType(type);
    setRowID(record.id);
    settaxAmt(record.taxDetails);
    settaxAmtModal(true);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  function onChangeStartDate(date, dateString) {
    setStartPeriod(date);
  }

  const getTaxStates = (country) => {
    setLoad(true)
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getEmployeeInfo = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactInformation = res.data.result;
          setCountriesDatas(contactInformation.countries);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const onCancelOnboardChecklist = () => {
    setOnboardChecklist(false);
  };

  const getChecklists = () => {
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setUserChecklist(res.data.result);
          if (
            !res.data.result.billEnabled ||
            !res.data.result.employeeOnboard 
          ) {
            setOnboardChecklist(true);
          }
        } else {
          setOnboardChecklist(false);

        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };


  const getCurrencies = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/wallets/currencies/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const result = res.data.result;
          setCurrenciesDatas(result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const closeAdd = () => {
    settaxCreate(false);
    // setRemitNhf("");
    // setEndPeriod("");
    // setRemitTax("");
    // setRemitTax("");
  };

  const deletetax = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/taxes/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          gettaxs();
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this tax?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletetax();
      },
      onCancel() {
      },
    });
  };

  const getPaygroup = (country) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const runtax = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to run this tax?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/taxes/run/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              gettaxs();
              setTimeout(() => {
                setSuccessStatus(false);
              }, 2000);
            } else {
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              gettaxs();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
              // setStatusMsg('Failed to connect to SalarioPay')
            }
          });
      },

      onCancel() {
      },
    });
  };

  const stoptax = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to stop this tax?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/taxes/stop/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              Notification("success", "Success", res.data.message);
              gettaxs();
              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            } else {
            }
          })
          .catch((err) => {
            if (err) {
              setSuccessStatus(true);
              setVisibleDraw(false);
              gettaxs();
              Notification("error", "Error", "Failed to connect to SalarioPay");

              // setTimeout(() => {
              //   setSuccessStatus(false)
              // }, 2000)
            }
          });
      },
      onCancel() {
      },
    });
  };

  const closeFailedMsg = () => {
    setFailedMsgModal(false);
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Admin?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
      },
    });
  };

  const updatetaxswitch = () => {
    setUpdateSwitch(true);
    settaxCreate(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/taxs/status/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          gettaxs();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          gettaxs();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createtax = () => {
    if(userChecklists.employeeOnboard && userChecklists.billEnabled){
    settaxCreate(true);
    setUpdateSwitch(false);
    setOnboardChecklist(false)
    }
    else{
      setOnboardChecklist(true)
    }
  };

  const columns = [
    {
      key: "amount",
      text: "Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "fee",
      text: "Fee",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "country",
      text: "Country",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
 
 

    {
      key: "narration",
      text: "Narration",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "size",
      text: "Size",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "period",
      text: " Period",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
    },
  ];
  const gettaxs = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/taxes/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const taxData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          settaxData(
            taxData.map((data) => ({
              id: data.id,
              amount: data.currency + ' ' + data.amount,
              fee: data.currency + ' ' + data.fee,
              narration: data.narration,
              state: data.state,
              currency:data.currency,
              country: data.country,
              taxDetails: data.taxAmount,
              size: data.size,
              period: data.period ? data.period.slice(0, 10) : "",
              status: data.status,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closetaxResponse = () => settaxResponse(false);

  const addtax = (e) => {
    e.preventDefault();
    const data = {
      country: country,
      currency: currency,
      employerId: sessionStorage.getItem("employer_id"),
      state: taxState ? taxState : "",
      period: startPeriod,
    };
    if (data.country && data.currency && data.period) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/taxes`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            setCountry("")
            setCurrency("")
            setTaxState("")
            setStartPeriod("")
            setSuccessMsg(res.data.message);
            setRemitNhf("");
            setEndPeriod("");
            if (res.data.result.skipped) {
              settaxSuccessResponseData(res.data.result.taxDetails);
              settaxResponse(true);
            }
            setTimeout(() => {
              setSuccess(false);
              gettaxs();
              settaxCreate(false);
            }, 3000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            if (res.data.result.skipped) {
              settaxErrorResponseData(res.data.result.skipped);
              settaxResponse(true);
              setActiveTab(2);
            }
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const viewErrorMsg = (errMsg, e) => {
    e.preventDefault();
    setFailedMsgModal(true);
    setFailedMsgs(errMsg);
  };

  const updatetax = (e) => {
    e.preventDefault();
    const data = {
      country: country,
      currency: currency,
      employerId: sessionStorage.getItem("employer_id"),
      state: taxState,
      period: startPeriod,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/taxes/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setRemitNhf("");
          setEndPeriod("");
          if (res.data.result.skipped) {
            settaxSuccessResponseData(res.data.result.taxDetails);
            settaxResponse(true);
          }
          setTimeout(() => {
            setSuccess(false);
            gettaxs();
            settaxCreate(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          if (res.data.result.skipped) {
            settaxErrorResponseData(res.data.result.skipped);
            settaxResponse(true);
            setActiveTab(2);
          } 
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
      getTaxStates(e.target.value);
      getPaygroup(e.target.value);
    }
  };

  useEffect(() => {
    gettaxs();
    getCurrencies();
    getEmployeeInfo();
    getChecklists()
    
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
    },
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closetaxAmtModal = () => {
    settaxAmtModal(false);
    setType(false);
    setCountry("")
    setCurrency("")
    setTaxState("")
    setStartPeriod("")
  };

  const paygroupOptions = paygroupData.map((paygroup) => {
    return <option value={paygroup.id}>{paygroup.groupName}</option>;
  });

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/taxes/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const taxData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          settaxData(
            taxData.map((data) => ({
              id: data.id,
              amount: data.currency + ' ' + data.amount,
              fee: data.fee,
              narration: data.narration,
              currency:data.currency,
              taxDetails: data.taxAmount,
              state: data.state,
              country: data.country,
              size: data.size,
              period: data.period ? data.period.slice(0, 10) : "",
              status: data.status,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="card-title">Tax</h1>
                <button
                  className="btn btn-info "
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createtax}
                >
                  Create Tax
                </button>{" "}
              </div>

              <div className="pt-5">
                {/* <div className='text-center'>
                  {deleteSuccess && (
                    <div className='pb-4'>
                      <Alert message='Role Deleted' type='error' showIcon />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className='pb-4'>
                      <Alert message={statusMsg} type='error' showIcon />
                    </div>
                  )}
                </div> */}
                <div style={{ overflowX: "auto" }} className="pb-4">
                  <ReactDatatable
                    config={configTable}
                    records={taxData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
                <div className="text-center pagination-part pt-5">
                <Pagination
                  current={page}
                  total={totalItems}
                  defaultPageSize={10}
                  onChange={pagination}
                  itemRender={itemRender}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link to={{ pathname: "/tax_details", state: { rowId } }}>
          <div style={{ color: "#000000", cursor: "pointer" }}>
            View tax Details
          </div>
        </Link>
        <hr />

        <div style={{ color: "#000000", cursor: "pointer" }} onClick={runtax}>
          Run Tax
        </div>
        <hr />
        <div style={{ color: "red", cursor: "pointer" }} onClick={stoptax}>
          Stop Tax
        </div>
        <hr />
        <div
          style={{ color: "#1FC157", cursor: "pointer" }}
          onClick={updatetaxswitch}
        >
          Update Tax
        </div>
        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete Tax
        </div>
        <hr />
       
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Tax" : "Update Tax"}
        visible={taxCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
       
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <DatePicker
                  onChange={onChangeStartDate}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Period"
                />
              </div>

              <div className="mb-3">
                <div className="form-floating">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={countryChange}
                    value={country}
                  >
                    <option selected>Select</option>
                    {countriesData.map((country) => {
                      return (
                        <option value={country.code}>{country.name}</option>
                      );
                    })}
                  </select>
                  <label for="floatingInput">Country</label>
                </div>
              </div>
              {taxListData.length > 0 && (
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setTaxState(e.target.value)}
                      value={taxState}
                    >
                      <option selected>Select</option>
                      {taxListData.map((tax) => {
                        return <option value={tax.code}>{tax.value}</option>;
                      })}
                    </select>
                    <label for="floatingInput">Tax State</label>
                  </div>
                </div>
              )}
              <div className="mb-3">
                <div className="form-floating">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCurrency(e.target.value)}
                    value={currency}
                  >
                    <option selected>Select</option>
                    {currencyDatas.map((currency) => {
                      return (
                        <option value={currency.currency}>
                          {currency.currency}
                        </option>
                      );
                    })}
                  </select>
                  <label for="floatingInput">Currency</label>
                </div>
              </div>

              {/* <div className="mb-3">
                <div className="form-floating">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPaygroup(e.target.value)}
                    value={paygroup}
                  >
                    <option selected>Select</option>
                    {paygroupData.map((paygroup) => {
                      return (
                        <option value={paygroup.id}>
                          {paygroup.groupName}
                        </option>
                      );
                    })}
                  </select>
                  <label for="floatingInput">Payment Group</label>
                </div>
              </div> */}
            </div>
          ) : (
            <>
              <div>
                <div className="mb-3">
                  <DatePicker
                    onChange={onChangeStartDate}
                    defaultValue={moment(startPeriod, dateFormat)} format={dateFormat} 
                    style={{
                      width: "100%",
                      height: "3.3rem",
                      borderRadius: "10px",
                    }}
                    placeholder="Period"
                  />
                </div>

                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={countryChange}
                      value={country}
                    >
                      <option selected>Select</option>
                      {countriesData.map((country) => {
                        return (
                          <option value={country.code}>{country.name}</option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Country</label>
                  </div>
                </div>

                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setTaxState(e.target.value)}
                        value={taxState}
                      >
                        <option selected>Select</option>
                        {taxListData.map((tax) => {
                          return <option value={tax.code}>{tax.value}</option>;
                        })}
                      </select>
                      <label for="floatingInput">Tax State</label>
                    </div>
                  </div>
                
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCurrency(e.target.value)}
                      value={currency}
                    >
                      <option selected>Select</option>
                      {currencyDatas.map((currency) => {
                        return (
                          <option value={currency.currency}>
                            {currency.currency}
                          </option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Currency</label>
                  </div>
                </div>
                {/* <div className="mb-3">
                <div className="form-floating">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPaygroup(e.target.value)}
                    value={paygroup}
                  >
                    <option selected>Select</option>
                    {paygroupData.map((paygroup) => {
                      return (
                        <option value={paygroup.id}>
                          {paygroup.groupName}
                        </option>
                      );
                    })}
                  </select>
                  <label for="floatingInput">Payment Group</label>
                </div>
              </div> */}
              </div>
            </>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addtax : updatetax}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={type === "amount" ? "tax Amounts" : "tax Fees "}
        visible={taxAmtModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closetaxAmtModal}
      >
        {type === "amount" ? (
          <div>
            {taxAmt.map((taxAmt) => {
              return (
                <ul>
                  <li>
                    {taxAmt.currency} {taxAmt.amount}
                  </li>
                </ul>
              );
            })}
          </div>
        ) : (
          <div>
            {taxAmt.map((taxFee) => {
              return (
                <ul>
                  <li>
                    {taxFee.currency} {taxFee.fee}
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </Modal>

      <Modal
        title={false}
        visible={taxResponse}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={"65%"}
        onCancel={closetaxResponse}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
                <Tabs defaultActiveKey={activeTab} onChange={callback}>
                  <TabPane tab="Success" key="1" style={{ color: "green" }}>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Code</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Tin</th>

                          </tr>
                        </thead>
                        <tbody>
                          {taxSuccessResponseData.map((data) => {
                            return (
                              <tr>
                                <td>{data.employeeCode}</td>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>{data.currency} {data.amount}</td>
                                <td>{data.tin}</td>

                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>

                  <TabPane tab="Failed" key="2">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Employee Code</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Tin</th>
                            <th scope="col">Error</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taxErrorResponseData.map((data) => {
                            return (
                              <tr>
                                <td>{data.employeeCode}</td>
                                <td>{data.firstName}</td>
                                <td>{data.lastName}</td>
                                <td>{data.tin}</td>
                                <td>
                                  <button
                                    onClick={viewErrorMsg.bind(
                                      this,
                                      data.messages
                                    )}
                                    style={{
                                      background: "red",
                                      color: "white",
                                      border: "1px solid red",
                                    }}
                                    size="small"
                                  >
                                    View Error
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Error Messages"
        visible={failedMsgModal}
        okText="Skip"
        onCancel={closeFailedMsg}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          {failedMsgs.map((msg) => {
            return (
              <div className="pb-2">
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>
      
      <Modal
        title=""
        visible={onboardChecklist}
        okText="Skip"
        maskClosable={false}
        width={600}
        onCancel={onCancelOnboardChecklist}
        onOk={onCancelOnboardChecklist}
      >
        <div className="container">
          <div className="check-area pt-2">
            <div className="container">
              <h4 className="text-center" style={{ color: "#1FC157" }}>
                Welcome to SalarioPay!
              </h4>
              <p style={{ color: "#1FC157", whiteSpace: "nowrap" }}>
                Are you ready to experience the next generation payroll for your
                bussiness
              </p>
              <hr />
              <div>
                <h5 className="text-center pt-3">Onboarding Checklist</h5>
                <p className="text-center">
                  Kindly complete the checklist below to get started
                </p>
              </div>

              <div className="check-area pt-2">
               
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.billEnabled
                        ? userChecklists.billEnabled
                        : ""
                    }
                  >
                      Fund account to run payroll, benefits, pension, and taxes{" "}
                  </Checkbox>
                  <Link
                    to={{ pathname: "/accounts"}}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Fund
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.employeeOnboard
                        ? userChecklists.employeeOnboard
                        : ""
                    }
                  >
                      Onboard employee in order to generate payroll{" "}
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/employee_onboarding",
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Onboard
                    </Button>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
