import React, { useState, useEffect, useRef } from "react";
import config from "../config";
import axios from "axios";
import Header from "./TheHeader";
import Cookie from "js-cookie";
import Sidebar from "./TheSidebar";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router-dom";
import TimeoutModal from "../utils/timeOutModal";
export default function Layout(props) {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  let logoutTimer;
  let inputRef = useRef();
  const location = window.location.pathname.replace("/", "");
  const searchName = window.location.search;

  console.log(location);
  useEffect(() => {
    sessionStorage.setItem("activeWindowState", location + searchName);
    if (!sessionStorage.getItem("token")) {
      window.location.href = "/login";
    }
  }, []);

  const getNotifications = () => {
    axios
      .get(`${config.baseUrl}/v1/notifications/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  logoutTimer = () => {
    setTimeout(() => {
      logout();
    }, 38000000); // 3 seconds
  };

  const onIdle = () => {
    togglePopup();
    logoutTimer();
  };

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  const logout = () => {
    Cookie.remove();
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("employeeID", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("activeWindowState", location + searchName);
    history.push("/login");
  };

  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      logoutTimer = null;
    }
    inputRef.current.reset();
    togglePopup();
    window.location.reload(false);
  };

  return (
    <div>
      <div class="page-container">
        <Header />
        <Sidebar />
        <div class="page-content">
          <div class="main-wrapper"> {props.children} </div>{" "}
        </div>{" "}
      </div>
      <IdleTimer
        ref={inputRef}
        element={document}
        stopOnIdle={true}
        onIdle={onIdle}
        timeout={380000}
      />

      <TimeoutModal
        showModal={showModal}
        setShowModal={setShowModal}
        togglePopup={togglePopup}
        handleStayLoggedIn={handleStayLoggedIn}
        logout={logout}
      />
    </div>
  );
}
