import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import { Drawer, Spin, Alert, Modal, Button, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import config from "../../../config";
import { notification } from "antd";
const { Option } = Select;

const TeamManagement = () => {
  const [teamManagementData, setTeamManagementData] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setemployee] = useState([]);
  const [getRoleData, setGetRoleData] = useState([]);
  const [role, setRole] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [refetchTeamMember, setRefetchTeamMember] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [employeeList,setEmployeesLists] = useState([])
  const [btnUpdateDisabled, setBtnUpdateDisabled] = useState(true);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [updateSwitchData, setUpdateSwitchData] = useState([]);
  const [updateRoleValue, setUpdateRoleValue] = useState("")
  const [updateEmployeeMail, setUpdateEmployeeMail] = useState("");

  const showDrawer = (record, e) => {
    e.preventDefault();
    setUpdateSwitchData(record);
    setVisibleDraw(true);
    setEmployeeId(record.id);
    setUpdateRoleValue(record?.role)
    setemployee(record.firstName + " " + record.lastName + " " + `(${record?.email})`)
    setRole(record?.roles[1])
  };

  const updateOptionSwitch = () => {
    setUpdateEmployeeMail(
      `${updateSwitchData.firstName} ${updateSwitchData.lastName}`
    );
    setUpdateSwitch(true);
    setInviteModal(true);
    setVisibleDraw(false);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const getTeam = () => {
    axios
      .get(
        `${config.baseUrl}/v1/team/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const TeamMgtData = res.data.result;
          setTeamManagementData(
            TeamMgtData.map((data) => ({
              id: data.id,
              firstName: `${data.firstName} ${data.otherName} `,
              lastName: data.lastName,
              email: data.workEmail,
              roles: data.roles.map((item) => {
                return `${item}, `;
              }),
            }))
          );
        } else {
        }
      })
      .catch((err) => {
      });
  };

  const getEmployees = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/paged?page=0&size=100&employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setEmployees(res.data.result)
          setEmployeesLists(
            res.data.result?.map((d) => ({
              value: d.id,
              label: d.firstName + " " + d.otherName + " " + d.lastName + " " + `(${d?.workEmail})` + " " + `(${d?.employeeCode})`,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
      });
  };

  const getRoles = () => {
    axios
      .get(`${config.baseUrl}/v1/options/TEAM_ROLE`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setGetRoleData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
      });
  };

  const handleChangeEmployee = (value) => {
    setemployee(value);
  };

  const handleChangeTeamRole = (value) => {
    setRole(value);
  };

  const getEmail = (employeee) => {
    var findEmail = employees?.find((d) => d?.id === employeee);
    return findEmail.workEmail;
  };

  const handleSubmitTeamMgt = (e) => {
    e.preventDefault();
    setLoad(true);

    const data = {
      email: getEmail(employee),
      employerId: sessionStorage.getItem("employer_id"),
      role: role,
    };
    axios
      .post(`${config.baseUrl}/v1/team/send-invite`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setLoad(false);
          Notification('success','Success',res.data?.message)
          setSuccessMsg(res.data.message);
          closeInviteModal()
          setRefetchTeamMember(!refetchTeamMember);
          setTimeout(() => {
            setInviteModal(false);
            setSuccess(false);
            setTeamData([]);
            setSuccessMsg("");
            setRole("");
          }, 2500);
        } else {
          Notification('error','Error',res.data?.message)
          setLoad(false);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const handleUpdateTeamMgt = (e) => {
    e.preventDefault();

    setLoad(true);

    const data = {
      email: updateSwitchData.email,
      employerId: sessionStorage.getItem("employer_id"),
      role: role,
    };
    axios
      .put(`${config.baseUrl}/v1/team/${employeeId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setLoad(false);
          Notification('success','Success',res.data?.message)
          setSuccessMsg(res.data.message);
          closeInviteModal()
          setRefetchTeamMember(!refetchTeamMember);
          setTimeout(() => {
            setInviteModal(false);
            setSuccess(false);
            setUpdateSwitch(false);
            setTeamData([]);
            setSuccessMsg("");
            setRole("");
            setUpdateEmployeeMail("");
          }, 2500);
        } else {
          Notification('error','Error',res.data?.message)
          setLoad(false);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  let employeeChoices = [];
  employees.map((data) => {
    return employeeChoices.push(
      <Option key={data.workEmail}>
        {`${data.firstName} ${data.otherName} ${data.lastName}`}
      </Option>
    );
  });

  let roleChoices = [];
  getRoleData.map((data) => {
    return roleChoices.push(<Option key={data.code}>{data.value?.replace(/_+/g, " ")}</Option>);
  });

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "firstName",
      text: "First Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "lastName",
      text: "Last Name",
      //   className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "roles",
      text: "Roles",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
    },
  ];

  const onSearch = (value) => {
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const removeTeamMember = () => {
    axios
      .delete(`${config.baseUrl}/v1/team/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification('success','Success',res.data?.message)
          setVisibleDraw(false);
          setRefetchTeamMember(!refetchTeamMember);

          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
          Notification('error','Error',res.data?.message)

        }
      })
      .catch((err) => {
      });
  };

  const resendInvite = () => {
    axios
    .post(`${config.baseUrl}/v1/team/resend-invite/${employeeId}`,{}, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      if (res.data.status) {
        setVisibleDraw(false);
        Notification('success','Success',res.data?.message)
        setRefetchTeamMember(!refetchTeamMember);
        setTimeout(() => {
          setDeleteSuccess(false);
        }, 2000);
      } else {
        Notification('error','Error',res.data?.message)
      }
    })
    .catch((err) => {
    });
  }

  const showRemoveConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to remove this Employee?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeTeamMember();
      },
      onCancel() {
      },
    });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };


  const onResendInvite = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to resend invite to this Employee?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        resendInvite();
      },
      onCancel() {
      },
    });
  }

  const toggleInviteModal = () => {
    setInviteModal(true);
  };

  const closeInviteModal = () => {
    setInviteModal(false);
    setUpdateSwitch(false);
    setRole('')
    setemployee("")
  };

  useEffect(() => {
    teamData.forEach((item) => {
      if (item !== "" && role !== "") {
        setBtnDisabled(false);
      }
    });
  }, [teamData, role]);

  useEffect(() => {
    if (role !== "") {
      setBtnUpdateDisabled(false);
    }
  }, [role]);

  useEffect(() => {
    getTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTeamMember]);

  useEffect(() => {
    getEmployees();
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 class="card-title">Team Management</h5>
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={toggleInviteModal}
                >
                  Invite Employee
                </button>{" "}
              </div>
              <div className="pt-0">
                <div className="text-center">
                 
                </div>
                <div className="pt-0" style={{ overflowX: "auto" }}>
                  <ReactDatatable
                    config={configTable}
                    records={teamManagementData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
            <Modal
              title={!updateSwitch ? "Invite Employee" : "Update Invite"}
              visible={inviteModal}
              okText="Submit"
              footer={false}
              width={700}
              maskClosable={false}
              onCancel={closeInviteModal}
            >
              <form>
                {!updateSwitch ? (
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="card table-widget">
                        <div className="card-body">
                          {load && (
                            <p className="text-center">
                              <Spin />
                            </p>
                          )}
                        
                          
                          <div class="mb-3 mt-0">
                            <label class="form-label">Select Employee</label>

                            <Select
                              value={employee}
                              style={{
                                width: "100%",
                                height: "45px",
                              }}
                              onChange={handleChangeEmployee}
                              options={employeeList}
                              showSearch
                              placeholder="Select "
                              optionFilterProp="children"
                              onSearch={onSearch}
                              filterOption={filterOption}
                            />
                          </div>
                          <div class="mb-0 mt-0">
                            <label class="form-label">Select Role</label>
                            <Select
                              mode="single"
                              allowClear
                              style={{ width: "100%" }}
                              value={role}
                              placeholder="Please select"
                              onChange={handleChangeTeamRole}
                            >
                              {roleChoices}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="card table-widget">
                        <div className="card-body">
                          {load && (
                            <p className="text-center">
                              <Spin />
                            </p>
                          )}
                         
                          <div class="mb-3 mt-0">
                            <label class="form-label">Select Employee</label>

                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Please select"
                              disabled={true}
                              value={employee}
                            />
                          </div>
                          <div class="mb-0 mt-0">
                            <label class="form-label">Select Role</label>
                            <Select
                              mode="single"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Please select"
                              onChange={handleChangeTeamRole}
                              value={role}
                            >
                              {roleChoices}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-end align-items-center">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={
                      !updateSwitch ? handleSubmitTeamMgt : handleUpdateTeamMgt
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showRemoveConfirm}
        >
          Remove Team Member
        </div>
        <hr />
        <div
          style={{ cursor: "pointer" }}
          onClick={onResendInvite}
        >
          Resend Invite
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateOptionSwitch}>
          Update
        </div>
        <hr />
      </Drawer>
    </Layout>
  );
};

export default TeamManagement;
