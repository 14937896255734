import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../../containers/Layout";
import config from "../../../../config";
import { useHttp } from "../../../../hooks/useHttps";
import axios from "axios";
import {
  Modal,
  Button,
  Spin,
  Alert,
  Space,
  Select,
  Pagination,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { set } from "js-cookie";
import PinInput from "react-pin-input";
import Tabs from "../../../employeeTabs";

const { confirm } = Modal;
const { Option } = Select;
export default function CompanyInfo() {
  const [walletInfo, setWalletInfo] = useState([]);
  const [paymentInfo, setpaymentInfo] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [countryCodee, setCountryCodee] = useState("");
  const [defCheck, setDefCheck] = useState(false);
  const [branchDatas, setBranchData] = useState([]);
  const [currencyCode, setCurrencyCode] = useState({});
  const [banklistss, setBanklistss] = useState([]);
  const [pinModal, setPinModal] = useState(false);
  const [methodID, setMethodid] = useState("");
  const [employersLists, setEmployerList] = useState([]);
  const [currencyCodeDatas, setCurrencyCodeDatas] = useState([]);
  const [paymentOption, setPaymentOption] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [pin, setPin] = useState("");
  const [onDefaultPaymentMethod, setDefaultPaymentMethod] = useState(false);
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [loadPayments, setLoadPayments] = useState(true);
  const [myPayments, setMyPayments] = useState(false);
  const [Nopayment, setNoPayments] = useState(false);

  const [bankSwitch, setBankSwitch] = useState(false);
  const [cryptoSwitch, setCryptoSwitch] = useState(false);
  const [isDefault, setDefault] = useState(false);
  const [accountNum, setAccountNum] = useState("");
  const [accountName, setAccountName] = useState("");
  const [country, setCountry] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankName, setBankName] = useState("");
  const [level, setLevel] = useState("");

  const [cryptoCurrency, setCrypto] = useState("");
  const [cryptoPlatform, setPlatform] = useState("");
  const [cryptoEmail, setCryptoEmail] = useState("");

  const [loading, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  const cancelPinSetup = () => {
    setPinModal(false);
    handleSubmit();
    setPin("");
  };

  const chekChnageDefaultPayment = (e) => {
    if (e.target?.checked) {
      setDefaultPaymentMethod(true);
    } else {
      setDefaultPaymentMethod(false);
    }
  };

  const [loading2, cryptoDatas] = useHttp(
    `${config.baseUrl}/v1/financial-institution/crypto`,
    []
  );
  const [loading3, paymentOptionsDatas] = useHttp(
    `${config.baseUrl}/v1/payment-options/filtered`,
    []
  );

  const [cardInfo, setCardInfo] = useState([]);
  const [addPaymentBox, setaddPaymentBox] = useState(false);
  const [walletLevel, setWalletLevel] = useState("");
  const [walletName, setWalletName] = useState("");
  const [cardLevel, setCardLevel] = useState("");
  const [cardName, setCardName] = useState("");

  const [manageBox, setManageBox] = useState(false);
  const [manageSwitch, setManageSwitch] = useState(false);

  const [userLevel, setUserLevel] = useState("");
  const [userName, setUserName] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [bankApiSuccess, setBankApiSuccess] = useState(false);
  const [bankApiSuccessMsg, setBankApiSuccessMsg] = useState("");
  const [bankApiError, setBankApiError] = useState(false);
  const [bankApiErrorMsg, setBankApiErrorMsg] = useState("");
  const [serverErrorMsg, setServerErrorMsg] = useState("");

  const [load, setLoad] = useState(false);
  const [bankId, setBankID] = useState("");
  const [cryptoId, setCryptoID] = useState("");

  const [alertError, setAlertError] = useState(false);
  const [bankError, setBankError] = useState(false);
  const [walletSwitch, setWalletSwitch] = useState(false);

  const [cardId, setCardId] = useState("");
  const [walletId, setWalletId] = useState("");

  const addCard = () => {
    setaddPaymentBox(true);
    setWalletSwitch(false);
  };

  const cryptoChange = (e) => {
    const cryptoInfo = cryptoDatas.find((v) => v.code === e.target.value);
    setCurrencyCode(cryptoInfo.code);
    setCrypto(cryptoInfo.name);
  };

  const addWallet = () => {
    setWalletSwitch(true);
    setaddPaymentBox(true);
    setManageBox(false);

  };
  useEffect(() => {
    getPaymentMethod();
    if (countryCode) {
      getBanks(countryCode);
      getCurrencyCode(countryCode);
    }
  }, [countryCode]);

  const closeManageBox = () => {
    setManageBox(false);
    setCrypto("");
    setCurrencyCode("");
    setAccountName("");
    setAccountNum("");
    setBankName("");
    setBranchName("");
    setLevel("");
    setCurrencyCode("");
    setCountry("");
    setCryptoEmail("");
    setPlatform("");
  };

  const employerChoices = [];
  employers.map((employer) => {
    employerChoices.push(
      <Option key={employer.id}>
        {employer.employerResponse.businessName}
      </Option>
    );
  });

  const handleChangeEmployer = (value) => {
    setEmployerList(value);
  };

  let ele = useRef();
  const handleSubmit = () => {
    ele.clear();
  };

  const getPaymentMethod = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/payment-methods/paged?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&size=10&page=0`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            const paymentInfo = res.data.result;
            setpaymentInfo(paymentInfo);
            setTotalItems(res.data.totalPages * 10);
            setMyPayments(true);
            setNoPayments(false);
            setLoadPayments(false);
          } else {
            setMyPayments(false);
            setNoPayments(true);
            setLoadPayments(false);
          }
        } else {
          setMyPayments(false);
          setNoPayments(true);
          setLoadPayments(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employeeId=${sessionStorage.getItem(
      "employeeID"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/payment-methods/employer/paged?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setpaymentInfo(paymentInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closeAddWallet = () => {
    setaddPaymentBox(false);
    setCrypto("");
    setCurrencyCode("");
    setAccountName("");
    setBankID(false);
    setAccountNum("");
    setBankName("");
    setBranchName("");
    setLevel("");
    setCurrencyCode("");
    setCountry("");
    setCryptoEmail("");
    setPlatform("");
  };

  const getCurrencyCode = (id) => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCurrencyCodeDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const postPin = (type, e) => {
    e.preventDefault();
    switch (type) {
      case "Bank":
        const dataBank = {
          accountName: accountName,
          accountIdentifier: accountNum,
          institutionCode: bankName,
          countryCode: country,
          currencyCode: currencyCode,
          paymentOption: paymentOption,
          employeeId: sessionStorage.getItem("employeeID"),
          institutionBranch: "string",
          institutionName: getBankName(bankName),
          pin: "string",
        };
        if (
          dataBank.accountName &&
          dataBank.accountIdentifier &&
          dataBank.institutionCode &&
          dataBank.countryCode
        ) {
          setPinModal(true);
        } else {
          setAlertError(true);
          setTimeout(() => {
            setAlertError(false);
          }, 2500);
        }
        break;
      case "Bank_Update":
        const dataBankUpdate = {
          accountName: accountName,
          accountIdentifier: accountNum,
          institutionCode: bankName,
          countryCode: currencyCode,
          currencyCode: currencyCode,
          paymentOption: paymentOption,
          employeeId: sessionStorage.getItem("employeeID"),
          institutionBranch: "string",
          institutionCode: bankName,
          institutionName: getBankName(bankName),
          pin: "string",
        };
        if (
          dataBankUpdate.accountName &&
          dataBankUpdate.accountIdentifier &&
          dataBankUpdate.institutionCode &&
          dataBankUpdate.institutionName &&
          dataBankUpdate.countryCode &&
          dataBankUpdate.paymentOption
        ) {
          setPinModal(true);
        } else {
          setAlertError(true);
          setTimeout(() => {
            setAlertError(false);
          }, 2500);
        }
        break;

      case "Crypto":
        const dataCrypto = {
          accountNumber: null,
          bankCode: null,
          accountName: null,
          countryCode: null,
          currencyCode,
          cryptoName: cryptoCurrency,
          cryptoWallet: cryptoPlatform,
          cryptoWalletEmail: cryptoEmail,
          pin,
          level,
          paymentOption: paymentOption,
        };
        if (
          dataCrypto.cryptoWallet &&
          dataCrypto.cryptoName &&
          dataCrypto.cryptoWalletEmail &&
          dataCrypto.level &&
          dataCrypto.currencyCode
        ) {
          setPinModal(true);
        } else {
          setAlertError(true);
          setTimeout(() => {
            setAlertError(false);
          }, 2500);
        }
        break;
      default:
        return;
    }
  };

  const getBankName = (id) => {
    var bank = bankData?.find((x) => x.bankCode === id);
    return bank?.bankName;
  };

  const addBankInfo = (e) => {
    e.preventDefault();
    const data = {
      accountName: accountName,
      accountIdentifier: accountNum,
      institutionCode: bankName,
      countryCode: country,
      currencyCode: currencyCode,
      paymentOption: paymentOption,
      employeeId: sessionStorage.getItem("employeeID"),
      institutionBranch: "string",
      institutionName: getBankName(bankName),
      isDefault: onDefaultPaymentMethod,
      pin: pin,
    };
    if (
      data.accountName &&
      data.accountIdentifier &&
      data.institutionCode &&
      data.countryCode &&
      data.currencyCode &&
      pin
    ) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/payment-methods`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            getPaymentMethod();
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              setSuccess(false);
              window.location.reload();
            }, 2000);
          } else {
            setError(true);
            Notification("error", "Error", res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      Notification("error", "Error", "Pin is required");
    }
  };

  const fetchAccName = (e) => {
    e.preventDefault();
    setAccountNum(e.target.value);
    const data = {
      accountNumber: accountNum,
      bankCode: bankName,
      accountName,
      countryCode: country,
      cryptoCurrencyCode: null,
      cryptoWallet: null,
      cryptoWalletEmail: null,
      currencyCode,
      level,
      paymentOption: paymentOption,
    };

    if (data.accountNumber && data.bankCode && data.countryCode) {
      setLoad(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bankName,
            accountNumber: accountNum,
            countryCode: country,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
            setBankApiSuccess(true);
            setBankApiSuccessMsg(res.data.message);
            setTimeout(() => {
              setBankApiSuccess(false);
            }, 1000);
          } else {
            setBankApiError(true);
            setBankApiErrorMsg(res.data.message);
            setTimeout(() => {
              setBankApiError(false);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setBankError(true);
      setTimeout(() => {
        setBankError(false);
      }, 2500);
    }
  };

  const manageCrypto = (id, code, email, wallet, level) => {
    setManageBox(true);
    setCrypto(code);
    setManageSwitch(false);
    setPaymentOption("CRYPTO_WALLET");
    setCryptoID(id);
    setCryptoEmail(email);
    setPlatform(wallet);
    setLevel(level);
  };

  const manageBank = (
    id,
    accName,
    accNum,
    bankCode,
    bank,
    country,
    code,
    codeCountry,
    def
  ) => {
    // console.log(currency)
    setManageBox(true);
    setAccountNum(accNum);
    setAccountName(accName);
    setBankID(bankCode);
    setCountry(country);
    setMethodid(id);
    setCurrencyCode(code);
    setCountryCode(codeCountry);
    setPaymentOption("BANK_ACCOUNT");
    setLevel(level);
    setBankName(bankCode);
    setManageSwitch(true);
    setDefault(def);
  };

  const walletDelete = () => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/billing-methods/${walletId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
          }, 2500);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const onClear = () => {
    setPin("");
    this.pin.clear();
  };

  const deleteAcc = (type) => {
    setLoad(true);
    axios
      .delete(
        `${config.baseUrl}/v1/payment-methods/${methodID}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const addCryptoInfo = (e) => {
    e.preventDefault();
    const data = {
      accountNumber: null,
      bankCode: null,
      accountName: null,
      countryCode: null,
      currencyCode,
      cryptoName: cryptoCurrency,
      cryptoWallet: cryptoPlatform,
      cryptoWalletEmail: cryptoEmail,
      pin,
      employeeId: sessionStorage.getItem("employeeID"),
      level,
      paymentOption: paymentOption,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/payment-methods`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCrypto("");
          setCurrencyCode("");
          setAccountName("");
          setAccountNum("");
          setBankName("");
          setBranchName("");
          setLevel("");
          setCurrencyCode("");
          setCountry("");
          setPinModal(false);
          setCryptoEmail("");
          getPaymentMethod();
          setPlatform("");
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            setaddPaymentBox(false);
          }, 2500);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const chekChnage = (e) => {
    if (e.target?.checked) {
      axios
        .put(
          `${config.baseUrl}/v1/payment-methods/default/${methodID}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            getPaymentMethod();
            setDefCheck(false);
            Notification("success", "Success", res.data.message);
            setManageBox(false);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  };

  const getBanks = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setBankData(res.data.result);
          setBanklistss(
            res.data?.result?.map((d) => ({
              value: d?.bankCode,
              label: d?.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
      getBanks(e.target.value);
      getCurrencyCode(e.target.value);
    }
  };

  const countryChangeEdit = (e) => {
    if (e.target.value) {
      const country = countryDatas.find((x) => x.name === e.target.value);
      setCountry(country.name);
      setCountryCodee(country.code);
      getBanks(country.code);
      getCurrencyCode(country.code);
    }
  };

  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const updateBank = (e) => {
    e.preventDefault();
    const data = {
      accountName: accountName,
      accountIdentifier: accountNum,
      institutionCode: bankName,
      countryCode: countryCode,
      currencyCode: currencyCode,
      paymentOption: paymentOption,
      employeeId: sessionStorage.getItem("employeeID"),
      institutionBranch: "string",
      institutionCode: bankName,
      institutionName: getBankName(bankName),
      pin: pin,
    };
    if (pin) {
      setLoad(true);
      axios
        .put(`${config.baseUrl}/v1/payment-methods/${methodID}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            getPaymentMethod();
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              setSuccess(false);
              window.location.reload();
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      Notification("error", "Error", "Pin is required");
    }
  };

  const updateCrypto = (e) => {
    e.preventDefault();
    const data = {
      accountName: null,
      accountNumber: null,
      bankCode: null,
      countryCode: null,
      currencyCode,
      cryptoWallet: cryptoPlatform,
      cryptoWalletEmail: cryptoEmail,
      employeeId: employersLists,
      currencyCode: null,
      level,
      paymentOption: paymentOption,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/payment-methods/${cryptoId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (!res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          getPaymentMethod();
          setTimeout(() => {
            setSuccess(false);
            setaddPaymentBox(false);
            setManageBox(false);
          }, 2500);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };
  const setPaymentFunc = (e) => {
    setPaymentOption(e.target.value);
    if (e.target.value === "BANK_ACCOUNT") {
      setBankSwitch(true);
      setCryptoSwitch(false);
    } else if (e.target.value === "CRYPTO_WALLET") {
      setCryptoSwitch(true);
      setBankSwitch(false);
    } else {
      setCryptoSwitch(false);
      setBankSwitch(false);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const showDeleteConfirm = (type, e) => {
    e.preventDefault();
    confirm({
      title: `Are you sure you want to delete this ${type} payment method?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteAcc(type);
      },
      onCancel() {},
    });
  };

  const countryOptions = countryDatas.map((country) => {
    return <option value={country.code}>{country.name}</option>;
  });

  const countryOptionsEdit = countryDatas.map((country) => {
    return <option value={country.name}>{country.name}</option>;
  });

  const cryptoOptions = cryptoDatas.map((crypto) => {
    return <option value={crypto.code}>{crypto.name}</option>;
  });

  const cryptoOptionsEdit = cryptoDatas.map((crypto) => {
    return <option value={crypto.name}>{crypto.name}</option>;
  });

  const bankOptions = bankData.map((bank) => {
    return <option value={bank.bankCode}>{bank.bankName}</option>;
  });

  const bankOptionsEdit = bankData.map((bank) => {
    return <option value={bank.bankCode}>{bank.bankName}</option>;
  });

  const currencyOptions = currencyCodeDatas.map((currency) => {
    return (
      <option value={currency.currencyCode}>{currency.currencyName}</option>
    );
  });

  const currencyOptionsEdit = currencyCodeDatas.map((currency) => {
    return (
      <option value={currency.currencyCode}>{currency.currencyName}</option>
    );
  });

  const paymentOptions = paymentOptionsDatas.map((option) => {
    return <option value={option.code}>{option.name}</option>;
  });

  const getCountryCode = (country) => {
    var findcode = countryDatas?.find((d) => d?.name === country);
    return findcode.code;
  };

  const getBankcode = (bank) => {
    var findcode = bankData?.find((d) => d?.bankName === bank);
    return findcode.bankCode;
  };

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <div className="d-flex justify-content-between align-items-center">
                <div>Payment methods</div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={addWallet}
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Add Payment Method
                </button>
              </div>
            </div>
            <div class="card-body">
              {myPayments && (
                <div>
                  {paymentInfo.map((pay) => {
                    if (pay.paymentOption === "BANK_ACCOUNT") {
                      return (
                        <div>
                          <div class="post">
                            <div class="post-header">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204742/StaticIcons/employer.png"
                                alt=""
                              />
                              <div class="post-info">
                                <span class="post-author">
                                   {pay.accountName}
                                </span>
                                <br />

                                <span class="post-date">
                                 {pay.institutionName + '-' + pay.accountIdentifier}
                                </span>
                               
                                <br />
                                <span class="post-date">
                                   {pay.countryName}
                                </span>
                                <br />
                                <span class="post-date">
                                   {pay.currencyCode}
                                </span>
                                <br />
                              </div>
                              <div class="post-header-actions">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  onClick={manageBank.bind(
                                    this,
                                    pay.id,
                                    pay.accountName,
                                    pay.accountIdentifier,
                                    pay.institutionCode,
                                    pay.institutionName,
                                    pay.countryName,
                                    pay.currencyCode,
                                    pay?.countryCode,
                                    pay?.isDefault
                                  )}
                                >
                                  Manage
                                </button>
                                <br />
                                <br />
                                <div>
                                  <b
                                    className="text-right ml-4"
                                    style={{
                                      color: pay.isDefault ? "green" : "",
                                    }}
                                  >
                                    {pay.isDefault ? "DEFAULT" : ""}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    } else if (pay.paymentOption === "CRYPTO_WALLET") {
                      return (
                        <div>
                          <div class="post">
                            <div class="post-header">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1630026690/StaticIcons/crypto_earning.png"
                                alt=""
                              />
                              <div class="post-info">
                                <span class="post-author">
                                  CRYPTO WALLET - {pay.cryptoWallet}
                                </span>
                                <br />
                                <span class="post-date">
                                  CRYPTO CURRENCY - {pay.cryptoName}
                                </span>
                                <br />
                                <span class="post-date">
                                  WALLET EMAIL - {pay.cryptoWalletEmail}
                                </span>
                                <br />
                                <b
                                  style={{
                                    color:
                                      pay.level === "PRIMARY"
                                        ? "green"
                                        : "orange",
                                  }}
                                >
                                  {pay.level}
                                </b>
                              </div>
                              <div class="post-header-actions">
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  onClick={manageCrypto.bind(
                                    this,
                                    pay.id,
                                    pay.cryptoCurrency,
                                    pay.cryptoWalletEmail,
                                    pay.cryptoWallet,
                                    pay.level
                                  )}
                                >
                                  Manage
                                </button>
                                <div>
                                  <b
                                    style={{
                                      color: pay.isDefault ? "green" : "",
                                    }}
                                  >
                                    {pay.isDefault ? "DEFAILT" : ""}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
              {Nopayment && (
                <div>
                  <p className="text-center py-5">
                    No Payment Method Available
                  </p>
                </div>
              )}

              {loadPayments && (
                <div className="text-center">
                  <Spin />
                </div>
              )}

              {paymentInfo?.length > 0 && (
                <div className="text-center pagination-part pt-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Add Payment Method"}
        visible={addPaymentBox}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAddWallet}
      >
        <div className="container">
          <form>
            {load && (
              <p className="text-center">
                <Spin />
              </p>
            )}
            <div class="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={setPaymentFunc}
              >
                <option selected>Select Payment Type</option>
                {paymentOptions}
              </select>
            </div>
            {bankSwitch && (
              <div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div class="">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={countryChange}
                        value={country}
                      >
                        <option selected>Select Country</option>
                        {countryOptions}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCurrencyCode(e.target.value)}
                        // value={currencyCode}
                      >
                        <option selected>Select Currency</option>
                        {currencyOptions}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="mb-4 row">
                  <label className="text-left">Select Bank </label>
                  <Select
                    showSearch
                    value={bankName}
                    style={{
                      width: "100%",
                      borderRadius: "10px !important",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val) => {
                      setBankName(val);
                    }}
                    options={banklistss}
                  />
                </div>
                <div className="mb-3 row">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={accountNum}
                      onBlur={fetchAccName}
                      onChange={(e) => setAccountNum(e.target.value)}
                    />
                    <label for="floatingInput">Account Number</label>
                  </div>
                </div>
                <div className="mb-2 row">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={accountName}
                      disabled
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                    <label for="floatingInput">Account Name</label>
                  </div>
                </div>
                <div className="container mb-4 row">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      onChange={chekChnageDefaultPayment}
                      checked={onDefaultPaymentMethod}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Make default payment method
                    </label>
                  </div>
                </div>

                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={postPin.bind(this, "Bank")}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            {cryptoSwitch && (
              <div>
                <div class="mb-3 row">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={cryptoChange}
                  >
                    <option selected>Select Crypto Currency</option>
                    {cryptoOptions}
                  </select>
                </div>
                <div class="mb-3 row">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPlatform(e.target.value)}
                    value={cryptoPlatform}
                  >
                    <option selected>Select Crypto Platform</option>
                    <option>BINANCE</option>
                    <option>LUNO</option>
                    <option>COINBASE</option>
                  </select>
                </div>
                <div className="mb-3 row">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      value={cryptoEmail}
                      onChange={(e) => setCryptoEmail(e.target.value)}
                    />
                    <label for="floatingInput">Crypto Wallet Email</label>
                  </div>
                </div>

                <div class="mb-3 row">
                  <label class="form-label">Employer(s)</label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onChange={handleChangeEmployer}
                    defaultValue={employersLists}
                  >
                    {employerChoices}
                  </Select>
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={addCryptoInfo}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>

      <Modal
        title={manageSwitch ? "Manage Bank Account" : "Manage Crypto Wallet"}
        visible={manageBox}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeManageBox}
      >
        <div className="container">
          <form>
            {load && (
              <p className="text-center">
                <Spin />
              </p>
            )}

            {manageSwitch ? (
              <div>
                <div class="mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={countryChangeEdit}
                    value={country}
                  >
                    <option selected>Select Country </option>
                    {countryOptionsEdit}
                  </select>
                </div>
                <div class="mb-3">
                  {/* <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setBankName(e.target.value)}
                    value={bankName}
                  >
                    <option selected>Select Bank </option>
                    {bankOptionsEdit}
                  </select> */}
                  <Select
                    showSearch
                    value={bankName}
                    className="mb-3"
                    style={{
                      width: "100%",
                      borderRadius: "10px !important",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val) => {
                      setBankName(val);
                    }}
                    options={banklistss}
                  />
                </div>
              

                <div class="mb-3 mt-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCurrencyCode(e.target.value)}
                    value={currencyCode}
                  >
                    <option selected>Select Currency </option>
                    {currencyOptionsEdit}
                  </select>
                </div>
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={accountNum}
                      onBlur={fetchAccName}
                      onChange={(e) => setAccountNum(e.target.value)}
                    />
                    <label for="floatingInput">Account Number</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={accountName}
                      disabled
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                    <label for="floatingInput">Account Name</label>
                  </div>
                </div>
                {!isDefault && (
                  <div className="mb-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={chekChnage}
                        checked={defCheck}
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Make default Payment method
                      </label>
                    </div>
                  </div>
                )}

                <div className="d-flex mt-2 justify-content-between align-items-center">
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{
                      background: "#EE6E83",
                      color: "white",
                      width: "25%",
                    }}
                    onClick={showDeleteConfirm.bind(this, "Bank Account")}
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={postPin.bind(this, "Bank_Update")}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div class="mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCrypto(e.target.value)}
                    value={cryptoCurrency}
                  >
                    <option selected>Select Crypto Currency</option>
                    {cryptoOptions}
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPlatform(e.target.value)}
                    value={cryptoPlatform}
                  >
                    <option selected>Select Crypto Platform</option>
                    <option>BINANCE</option>
                    <option>LUNO</option>
                    <option>COINBASE</option>
                  </select>
                </div>
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      value={cryptoEmail}
                      onChange={(e) => setCryptoEmail(e.target.value)}
                    />
                    <label for="floatingInput">Crypto Wallet Email</label>
                  </div>
                </div>

                <div class="mb-3">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setLevel(e.target.value)}
                    value={level}
                  >
                    <option selected>Select Level</option>
                    <option>PRIMARY</option>
                    <option>SECONDARY</option>
                  </select>
                </div>
                <div className="d-flex justify-content-between align-content-center">
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{
                      background: "#EE6E83",
                      color: "white",
                      width: "25%",
                    }}
                    onClick={showDeleteConfirm.bind(this, "Crypto Wallet")}
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={updateCrypto}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          <div className="text-center">
            {load && <Spin />}
            <div>
              <label className="text-left pt-4">Pin</label>
            </div>

            <PinInput
              length={4}
              value={pin}
              secret
              onChange={(value, index) => setPin(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              ref={(n) => (ele = n)} /*Set ref */
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-center">
            {bankId ? (
              <button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={updateBank}
              >
                Submit
              </button>
            ) : (
              <button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={bankSwitch ? addBankInfo : addCryptoInfo}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
