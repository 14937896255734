/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ProfileUserInfo from "./userDropDown";
import axios from "axios";
import config from "../config";
import menu from "../constants/navmenus";
import { useLocation, Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import logo from "../img/salariopay-logo.png";
import { Drawer, notification } from "antd";
const role = sessionStorage.getItem("activeRole");
let photoUrl;
const defaultPhoto =
  "https://res.cloudinary.com/didqqm6jl/image/upload/v1630245510/StaticIcons/user.png";

export default function TheHeader() {
  const [visible, setVisible] = useState(false);
  const [employerProfile, setEmployerProfile] = useState({});

  const showDrawer = () => {
    setVisible(true);
  };

  const history = useHistory();

  const [active, setActive] = useState("");
  const location = useLocation();
  const locationB = window.location.pathname.replace("/", "");
  const searchName = window.location.search;

  const getActive = (id) => {
    setActive(id);
  };

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactDetails = res.data.result;
          setEmployerProfile(contactDetails);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (role === "EMPLOYER") {
      getContactDetails();
    }
  }, [role]);

  const onClose = () => {
    setVisible(false);
  };

  const logout = () => {
    sessionStorage.setItem("employer_id", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("token", "");
    sessionStorage.getItem("employeeID", "");
    sessionStorage.setItem("logOutRole", role);
    handleLogOut();
  };

  const getProfile = () => {
    axios
      .get(`${config.baseUrl}/v1/users`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          photoUrl = res.data.result.photoUrl;
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Logout",
      description: msg,
    });
  };

  const handleLogOut = () => {
    axios
      .get(`${config.baseUrl}/v1/auth/logout`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          sessionStorage.setItem("employer_id", "");
          sessionStorage.setItem("userId", "");
          sessionStorage.setItem("token", "");
          sessionStorage.getItem("employeeID", "");
          sessionStorage.setItem("logOutRole", role);
          sessionStorage.setItem("activeWindowState", locationB + searchName);
          openNotificationWithIcon("success", res.data.message);
          setTimeout(() => {
            Cookies.remove("salarioPayUserInfo");
            history.replace("/login");
          }, 1500);
        } else {
          openNotificationWithIcon(res.message);
        }
      })
      .catch((err) => {
        if (err) {
          Cookies.remove("salarioPayUserInfo");
          history.replace("/login");
        }
      });
  };

  useEffect(() => {
    getProfile();
    // const interval = setInterval(() => {
    //   getNotifications()
    // }, 6000);
    // return () => clearInterval(interval);
  }, []);

  const getNotifications = () => {
    axios
      .get(`${config.baseUrl}/v1/notifications/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err?.message);
          Cookies.remove("salarioPayUserInfo");
          sessionStorage.setItem("token", "");
          window.location.href = "/login";
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <div>
      <div class="page-header">
        <nav class="navbar navbar-expand-lg d-flex justify-content-between">
          <div class="" id="navbarNav">
            <div class="nav-item text-left mobile-view" onClick={showDrawer}>
              <i class="fa fa-bars fa-2x" aria-hidden="true"></i>
            </div>

            <ul class="navbar-nav" id="leftNav">
              <li class="nav-item">
                <a class="nav-link" id="" href="#">
                  <i data-feather="arrow-left" />
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://salariopay.tawk.help/">
                  Help Center
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  target="blank"
                  href="https://salariopay.com/contact-us"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div class="logo">
            <a class="navbar-brand" href="index.html" />
          </div>
          <div class="" id="headerNav">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link profile-dropdown"
                  href="#"
                  id="profileDropDown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    width="100"
                    className={photoUrl ? "" : ""}
                    src={
                      employerProfile?.logoUrl === null
                        ? defaultPhoto
                        : photoUrl
                    }
                  />
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end profile-drop-menu"
                  aria-labelledby="profileDropDown"
                >
                  <ProfileUserInfo logout={logout} />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <Drawer
        title={false}
        placement="left"
        onClose={onClose}
        visible={visible}
      >
        <div class="text-center">
          <img src={logo} className="w-75 pt-5" />
        </div>
        <div className="page-side d-flex justify-content-start align-items-center">
          <ul class="list-unstyled accordion-menu">
            <br />
            <li class="sidebar-title"> Main </li> <br />
            {menu?.map((men) => {
              return (
                <li
                  class={men?.path === location.pathname && `pactive-page`}
                  style={{ whiteSpace: "nowrap", marginBottom: "1.5rem" }}
                >
                  <Link to={men?.path}>
                    <img
                      src={men?.icon}
                      alt=""
                      style={{ paddingRight: "1rem" }}
                      width="34"
                      className="home"
                    />{" "}
                    {men?.name}{" "}
                  </Link>{" "}
                </li>
              );
            })}
          </ul>{" "}
        </div>
      </Drawer>
    </div>
  );
}
